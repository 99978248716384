import { Body, Button, TextInput, useTheme } from "@merit/frontend-components";
import { Controller, useForm } from "react-hook-form";
import { ScrollView, StyleSheet, View } from "react-native";
import { SelectInput } from "@src/components";
import { useEffect } from "react";
import { useGetTestProps } from "@src/hooks";
import { useRemoveMerit } from "@src/api/issuance";
import { useSendRejectMeritEmail } from "@src/api/person-experience-backend";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import type { FC } from "react";
import type { Merit } from "@src/api/issuance";

const toOption = (str: string) => ({ label: str, value: str });

const REMOVAL_REASONS = [
  "The information on this merit is incorrect",
  "I don\u2019t need this merit anymore",
  "This appears to be a duplicate merit",
  "Spam",
  "Other",
] as const;

const REMOVAL_OPTIONS = REMOVAL_REASONS.map(toOption);

const schema = z.object({
  containerID: z.string().uuid(),
  memberEmailAddress: z.string(),
  memberFirstName: z.string(),
  orgName: z.string(),
  rejectionDescription: z
    .string({ required_error: "Requires at least 20 characters" })
    .trim()
    .min(20, "Requires at least 20 characters")
    .max(2000),
  rejectionReason: z.enum(REMOVAL_REASONS),
});

type RemoveMeritFormProps = {
  readonly onRemove?: () => Promise<void> | void;
  readonly onCancel?: () => Promise<void> | void;
  readonly merit: Merit;
};

export const RemoveMeritForm: FC<RemoveMeritFormProps> = ({ merit, onCancel, onRemove }) => {
  const { theme } = useTheme();
  const removeMerit = useRemoveMerit();
  const sendRejectMeritEmail = useSendRejectMeritEmail();
  const getTestProps = useGetTestProps();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    resolver: (values, context, options) => {
      const { rejectionReason, ...rest } = values;

      const transformed = {
        rejectionReason: rejectionReason?.value,
        ...rest,
      };

      return zodResolver(schema)(transformed, context, options);
    },
  });

  useEffect(() => {
    setValue("containerID", merit.id);
    setValue("memberFirstName", merit.fieldMap["First Name"]);
    setValue("memberEmailAddress", merit.fieldMap.Email);
    setValue("orgName", merit.transformedFields.orgName);
  }, [setValue, merit.id, merit.fieldMap, merit.transformedFields.orgName]);

  const styles = StyleSheet.create({
    actionsContainer: {
      flexDirection: "row",
      justifyContent: "flex-end",
      paddingTop: theme.spacing.l,
    },
    cancelButtonContainer: {
      marginRight: theme.spacing.s,
    },
    container: {
      width: "100%",
    },
    formContainer: {},
    inputContainer: {
      marginBottom: theme.spacing.l,
    },
  });

  const handleCancel = () => {
    onCancel?.();
  };

  const handleRemove = handleSubmit(body => {
    removeMerit.mutate(merit.id, {
      onSettled: () => {
        // The onSuccess handler wont run if the component is unmounted before removeMerit finishes
        onRemove?.();
      },
      onSuccess: () => {
        sendRejectMeritEmail.mutate(body);
      },
    });
  });

  // use non-scrolling ScrollView as parent container to allow tap to dismiss keyboard
  return (
    <ScrollView scrollEnabled={false} style={styles.container}>
      <View style={styles.formContainer}>
        <View style={styles.inputContainer}>
          <Controller
            control={control}
            name="rejectionReason"
            render={({ field: { onBlur, onChange, value } }) => (
              <SelectInput
                data={REMOVAL_OPTIONS}
                label="Removal reason *"
                labelField="label"
                onBlur={onBlur}
                onChange={onChange}
                placeholder="Select reason"
                value={value?.label}
                valueField="value"
                {...getTestProps({
                  elementId: "removalReasonInput",
                  elementName: "RemoveMeritForm",
                })}
              />
            )}
          />
          {errors.rejectionReason !== undefined && (
            <Body
              color={theme.colors.text.alert.critical}
              {...getTestProps({
                elementId: "removalReasonError",
                elementName: "RemoveMeritForm",
              })}
            >
              {errors.rejectionReason.message as string}
            </Body>
          )}
        </View>
        <View style={styles.inputContainer}>
          <Controller
            control={control}
            name="rejectionDescription"
            render={({ field: { onBlur, onChange, value } }) => (
              <TextInput
                label="Additional details *"
                maxLength={2000}
                numberOfLines={4}
                onBlur={onBlur}
                onChangeText={onChange}
                placeholder="Please describe your issue"
                value={value}
                {...getTestProps({
                  elementId: "additionalDetailsInput",
                  elementName: "RemoveMeritForm",
                })}
              />
            )}
          />
          {errors.rejectionDescription !== undefined && (
            <Body
              color={theme.colors.text.alert.critical}
              {...getTestProps({
                elementId: "additionalDetailsError",
                elementName: "RemoveMeritForm",
              })}
            >
              {errors.rejectionDescription.message as string}
            </Body>
          )}
        </View>
      </View>
      <View style={styles.actionsContainer}>
        <View style={styles.cancelButtonContainer}>
          <Button
            onPress={handleCancel}
            text="Cancel"
            type="secondary"
            {...getTestProps({
              elementId: "cancelButton",
              elementName: "RemoveMeritForm",
            })}
          />
        </View>
        <Button
          onPress={handleRemove}
          text="Remove"
          type="destructive"
          {...getTestProps({
            elementId: "removeButton",
            elementName: "RemoveMeritForm",
          })}
        />
      </View>
    </ScrollView>
  );
};
