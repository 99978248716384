/* eslint-disable functional/immutable-data */
/* eslint-disable no-param-reassign */

import { getExternalState, itemIsUnobscured, itemWillBeUnobscured } from "./utils";
import { useCallback, useEffect, useImperativeHandle, useRef } from "react";
import { useImmer } from "use-immer";
import type {
  InternalState,
  ListElement,
  UseListControllerArgs,
  UseListControllerReturn,
} from "./types";
import type {
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
} from "react-native";

export const useListController = <T>({
  data,
  keyExtractor,
  onStateChange,
  ref,
}: UseListControllerArgs<T>): UseListControllerReturn<T> => {
  const [state, setState] = useImmer<InternalState>({
    flag: false,
    index: 0,
    itemEdges: [],
    position: 0,
    scrollViewEdges: undefined,
  });

  const scrollViewRef = useRef<ScrollView>(null);

  // Propagate state
  useEffect(() => {
    onStateChange?.(getExternalState(state));
  }, [state, onStateChange]);

  // Reset item edges
  useEffect(() => {
    setState(draft => {
      draft.itemEdges = [];
    });
  }, [data, setState]);

  // Reset the index
  useEffect(() => {
    setState(draft => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const idx = draft.itemEdges.findIndex((_, idx) => itemIsUnobscured(draft, idx));

      // itemEdges isn't set on the first render
      if (idx === -1) {
        return;
      }

      draft.index = idx;
    });
  }, [state.flag, setState]);

  // If we haven't reached the end, move to the next item
  const handleNext = useCallback(() => {
    setState(draft => {
      const atEnd = itemWillBeUnobscured(draft, -1);
      if (atEnd) {
        return;
      }

      draft.index += 1;
      scrollViewRef.current?.scrollTo({ animated: true, x: draft.itemEdges[draft.index].left });
    });
  }, [setState]);

  // If we haven't reached the start, move to the previous item
  const handlePrevious = useCallback(() => {
    setState(draft => {
      const atStart = itemWillBeUnobscured(draft, 0);
      if (atStart) {
        return;
      }

      draft.index -= 1;
      scrollViewRef.current?.scrollTo({ animated: true, x: draft.itemEdges[draft.index].left });
    });
  }, [setState]);

  // Set item edges, then flip a flag
  const handleItemLayout = (event: LayoutChangeEvent, idx: number) => {
    setState(draft => {
      const { height, width, x, y } = event.nativeEvent.layout;

      draft.itemEdges[idx] = {
        bottom: y + height,
        left: x,
        right: x + width,
        top: y,
      };

      if (draft.itemEdges.length >= data.length) {
        draft.flag = !draft.flag;
      }
    });
  };

  // Set the ScrollView edges
  const handleScrollViewLayout = (event: LayoutChangeEvent) => {
    setState(draft => {
      const { height, width, x, y } = event.nativeEvent.layout;

      draft.scrollViewEdges = {
        bottom: y + height,
        left: x,
        right: x + width,
        top: y,
      };
    });
  };

  // Set the position from the ScrollView's state
  const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    setState(draft => {
      draft.position = event.nativeEvent.contentOffset.x;
    });
  };

  // We need onLayout to run every time the array changes, so that we can keep the item
  // edges updated
  const getKey = (item: T, index: number, arr: readonly T[]): string =>
    `${keyExtractor(item, index)}:${arr.length}`;

  useImperativeHandle<ListElement, ListElement>(
    ref,
    () => ({
      next: handleNext,
      previous: handlePrevious,
    }),
    [handleNext, handlePrevious]
  );

  return {
    getKey,
    handleItemLayout,
    handleScroll,
    handleScrollViewLayout,
    scrollViewRef,
  };
};
