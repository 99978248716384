import { Body, Heading, Link, useTheme } from "@merit/frontend-components";
import { Image, StyleSheet, View, useWindowDimensions } from "react-native";
import { useEffect } from "react";
import { useGetTestProps, useLayoutType } from "@src/hooks";
import { useOnboardingStore } from "@src/stores";
import OnboardingComplete from "@src/assets/images/onboarding-complete.png";

const OnboardingMeritsBodyComplete = () => {
  const { height } = useWindowDimensions();
  const getTestProps = useGetTestProps();
  const { theme } = useTheme();
  const layoutType = useLayoutType();
  const setOnboardingStep = useOnboardingStore(state => state.setOnboardingStep);

  const styles = StyleSheet.create({
    container: {
      alignItems: "center",
      height: (height / 4) * 3, // not ideal, this serves as an approximation of vertical centering without flex: 1 due to a ScrollView ancestor
      justifyContent: "center",
      padding: layoutType === "desktop" ? theme.spacing.xxl : theme.spacing.l,
    },
    image: {
      height: 120,
      marginBottom: layoutType === "desktop" ? theme.spacing.xxl : theme.spacing.l,
      width: 120,
    },
    text: {
      marginBottom: layoutType === "desktop" ? theme.spacing.xxl : theme.spacing.l,
      textAlign: "center",
    },
  });

  const onNavigatePress = () => {
    setOnboardingStep("COMPLETE");
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setOnboardingStep("COMPLETE");
    }, 10000);

    return () => {
      clearTimeout(timeout);
    };
  }, [setOnboardingStep]);

  return (
    <View style={styles.container}>
      <Image
        source={OnboardingComplete}
        style={styles.image}
        {...getTestProps({
          elementId: "completeImage",
          elementName: "OnboardingMeritsBodyComplete",
        })}
      />
      <Heading
        level="1"
        style={styles.text}
        testProps={{
          elementId: "allSet",
          elementName: "OnboardingMeritsBodyComplete",
          screenName: "OnboardingMerits",
        }}
      >
        You're all set!
      </Heading>
      <Heading
        level="2"
        style={styles.text}
        testProps={{
          elementId: "meritsUpdated",
          elementName: "OnboardingMeritsBodyComplete",
          screenName: "OnboardingMerits",
        }}
      >
        Your merits have been updated.
      </Heading>
      <Body
        style={styles.text}
        testProps={{
          elementId: "bodyText",
          elementName: "OnboardingMeritsBodyComplete",
          screenName: "OnboardingMerits",
        }}
      >
        Let's get you to where you need to be! If you aren't redirected in 10 seconds,{" "}
        <Link
          onPress={onNavigatePress}
          testProps={{
            elementId: "navigateButton",
            elementName: "OnboardingMeritsBodyComplete",
            screenName: "OnboardingMerits",
          }}
        >
          click here
        </Link>
      </Body>
    </View>
  );
};

export { OnboardingMeritsBodyComplete };
