import { ConnectedApps, MyMerits, Notifications, SideBarContentHeader } from "./components";
import { ScrollView, StyleSheet } from "react-native";
import { useAccountMerit } from "@src/api/issuance";
import { useNavigation } from "@src/hooks";
import { useTheme } from "@merit/frontend-components";

export const Home = () => {
  const { data: accountMerit } = useAccountMerit();
  const navigation = useNavigation();
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
      padding: theme.spacing.l,
    },
  });

  const handleFocus = () => {
    navigation.navigate("Merits");
  };

  return (
    <>
      <SideBarContentHeader
        onFocus={handleFocus}
        title={`Hi, ${accountMerit?.fieldMap["Chosen Name"] ?? ""}`}
      />
      <Notifications />
      <ScrollView style={styles.container}>
        <ConnectedApps />
        <MyMerits />
      </ScrollView>
    </>
  );
};
