import { ActivityIndicator, ScrollView, StyleSheet, View } from "react-native";
import { Constants, Log } from "@src/utils";
import { EmptyState } from "../EmptyState";
import { MeritActions } from "../MeritActions";
import { MeritSummary } from "../MeritSummary";
import { MeritTabs } from "../MeritTabs";
import { Modal, useTheme } from "@merit/frontend-components";
import { RejectMeritForm, RemoveMeritForm } from "@src/components";
import { useAcceptMerit } from "@src/api/issuance";
import { useConfig, useGetTestProps, useNavigation, useRoute } from "@src/hooks";
import { useMeritDetails } from "./hooks";
import { useState } from "react";
import type { MeritAction } from "../MeritActions";
import type { MeritDetailsProps } from "./types";
import type { ViewStyle } from "react-native";

export const MeritDetailsDesktop = ({ hideActions = false, meritId }: MeritDetailsProps) => {
  const config = useConfig();
  const getTestProps = useGetTestProps();
  const route = useRoute();
  const { theme } = useTheme();
  const acceptMerit = useAcceptMerit();
  const { goBackOrGoHome } = useNavigation();

  const { matchingMerit, showEmptyState, showLoadingState } = useMeritDetails({ meritId });

  const [isRejectMeritModalOpen, setIsRejectMeritModalOpen] = useState(false);
  const [isRemoveMeritModalOpen, setIsRemoveMeritModalOpen] = useState(false);

  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly tabsSectionContainer: ViewStyle;
    readonly topSectionContainer: ViewStyle;
  }>({
    container: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
      marginHorizontal: "auto",
      marginTop: theme.spacing.xxl,
      paddingTop: theme.spacing.xxl,
      width: 520,
    },

    tabsSectionContainer: {
      flex: 1,
    },
    topSectionContainer: {
      marginHorizontal: theme.spacing.l,
    },
  });

  const openRemoveMeritModal = () => {
    setIsRemoveMeritModalOpen(true);
  };

  const closeRemoveMeritModal = () => {
    setIsRemoveMeritModalOpen(false);
  };

  const handleRemove = () => {
    closeRemoveMeritModal();
    goBackOrGoHome();
  };

  const openRejectMeritModal = () => {
    setIsRejectMeritModalOpen(true);
  };

  const closeRejectMeritModal = () => {
    setIsRejectMeritModalOpen(false);
  };

  const handleReject = () => {
    closeRejectMeritModal();
    goBackOrGoHome();
  };

  if (showLoadingState) {
    return (
      <View style={styles.container}>
        <ActivityIndicator />
      </View>
    );
  }

  if (showEmptyState || matchingMerit === undefined) {
    Log.error(`Failed to fetch merits or find merit with ID: ${meritId}`);

    return (
      <View style={styles.container}>
        <EmptyState />
      </View>
    );
  }

  const isPending = matchingMerit.state?.name === "pending";
  const isAccountMerit = matchingMerit.templateId === config.remote?.accountMeritTemplateID;

  const actions = (() => {
    const removeAction: MeritAction = {
      disabled: isAccountMerit,
      id: "remove",
      onPress: openRemoveMeritModal,
      testProps: {
        elementId: "removeMerit",
        elementName: "MeritDetails",
        screenName: route.name,
      },
      text: "Remove",
      type: "destructive",
    };
    const acceptAction: MeritAction = {
      id: "accept",
      onPress: () => {
        acceptMerit.mutateAsync(meritId);
      },
      testProps: {
        elementId: "acceptMerit",
        elementName: "MeritDetails",
        screenName: route.name,
      },
      text: "Accept",
      type: "primary",
    };
    const rejectAction: MeritAction = {
      disabled: isAccountMerit,
      id: "reject",
      onPress: openRejectMeritModal,
      testProps: {
        elementId: "rejectMerit",
        elementName: "MeritDetails",
        screenName: route.name,
      },
      text: "Reject",
      type: "destructive",
    };

    if (isPending) {
      return [acceptAction, rejectAction];
    }

    return [removeAction];
  })();

  return (
    <>
      <ScrollView>
        <View
          style={styles.container}
          {...getTestProps({ elementId: meritId, elementName: "MeritDetails" })}
        >
          <View style={styles.topSectionContainer}>
            <MeritSummary merit={matchingMerit} />
          </View>
          <View style={styles.tabsSectionContainer}>
            <MeritTabs meritId={meritId} />
          </View>
        </View>
      </ScrollView>
      {hideActions ? null : (
        <>
          <MeritActions actions={actions} />
          {isRejectMeritModalOpen ? (
            <Modal
              maxWidth={Constants.Sizes.MODAL}
              onClose={closeRejectMeritModal}
              title="Reject this merit"
              titleIconName="warningMediumCritical"
              width="100%"
            >
              <RejectMeritForm
                merit={matchingMerit}
                onCancel={closeRejectMeritModal}
                onReject={handleReject}
              />
            </Modal>
          ) : null}
          {isRemoveMeritModalOpen && (
            <Modal
              maxWidth={Constants.Sizes.MODAL}
              onClose={closeRemoveMeritModal}
              title="Remove this merit"
              titleIconName="warningMediumCritical"
              width="100%"
            >
              <RemoveMeritForm
                merit={matchingMerit}
                onCancel={closeRemoveMeritModal}
                onRemove={handleRemove}
              />
            </Modal>
          )}
        </>
      )}
    </>
  );
};
