import type { MeritSorter } from "./types";

export const byNameThenOrg: MeritSorter = (meritA, meritB) => {
  const nameCompare = meritA.name?.localeCompare(meritB.name ?? "") ?? 0;

  if (nameCompare === 0) {
    return meritA.fieldMap["Issuing Org Name"].localeCompare(meritB.fieldMap["Issuing Org Name"]);
  }

  return nameCompare;
};
