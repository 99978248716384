import { Body, Button, Heading, useTheme } from "@merit/frontend-components";
import { Image, StyleSheet, View } from "react-native";
import { MERIT_LOGOTYPE } from "@src/utils/constants/sizes";
import { MOBILE_BREAKPOINT_FOR_LOGIN_SCREEN_ONLY } from "./constants";
import { useLayoutType, useLogin, useRoute } from "@src/hooks";
import MeritLogotype from "@src/assets/images/merit-logotype.png";
import type { ImageStyle, TextStyle, ViewStyle } from "react-native";

export const Login = () => {
  const route = useRoute();
  const { theme } = useTheme();
  const layoutType = useLayoutType(MOBILE_BREAKPOINT_FOR_LOGIN_SCREEN_ONLY);
  const styles = StyleSheet.create<{
    readonly heading: ViewStyle;
    readonly meritLogotype: ImageStyle;
    readonly subheading: TextStyle;
    readonly container: ViewStyle;
    readonly loginButton: ViewStyle;
  }>({
    container: {
      marginBottom: layoutType === "desktop" ? undefined : theme.spacing.xl,
    },
    heading: {
      marginBottom: theme.spacing.m,
    },
    loginButton: {
      maxWidth: 200,
    },
    meritLogotype: {
      ...MERIT_LOGOTYPE.m,
      marginBottom: theme.spacing.xxl,
    },
    subheading: {
      ...theme.fontSizes.m,
      marginBottom: theme.spacing.xxl,
    },
  });

  const promptLogin = useLogin();

  return (
    <View style={styles.container}>
      <Image source={MeritLogotype} style={styles.meritLogotype} />
      <Heading
        level="1"
        style={styles.heading}
        testProps={{ elementId: "mainHeading", elementName: "Login", screenName: route.name }}
      >
        Welcome
      </Heading>
      <Body style={styles.subheading}>
        Please click “Login” and enter your details to access your account
      </Body>
      <View style={styles.loginButton}>
        <Button
          accessibilityLabel="Login Button"
          onPress={() => {
            promptLogin();
          }}
          size="large"
          testProps={{ elementId: "login", elementName: "Login", screenName: route.name }}
          text="Login"
        />
      </View>
    </View>
  );
};
