import { Badge } from "@merit/frontend-components";
import { useRoute } from "@react-navigation/native";
import type { BadgeProps } from "@merit/frontend-components";
import type { Container } from "@merit/issuance-client";

type MeritStatusBadgeProps = {
  readonly merit?: Container;
};

type ColorText = {
  readonly color: BadgeProps["color"];
  readonly text: string;
};

const STATUS_TO_COLOR_TEXT_MAP: Record<string, ColorText> = {
  accepted: {
    color: "jade",
    text: "Active",
  },
  pending: {
    color: "citrine",
    text: "Pending",
  },
  unissued: {
    color: "coral",
    text: "Inactive",
  },
};

const MeritStatusBadge = ({ merit }: MeritStatusBadgeProps) => {
  const route = useRoute();

  // special handling of active/inactive Status (separate from State)
  if (merit?.active === false) {
    return (
      <Badge
        color="coral"
        testProps={{
          elementId: merit.id,
          elementName: "MeritStatusBadge",
          screenName: route.name,
        }}
        text="Inactive"
      />
    );
  }

  if (merit?.state?.name === undefined || !(merit.state.name in STATUS_TO_COLOR_TEXT_MAP)) {
    return null;
  }

  const { color, text } = STATUS_TO_COLOR_TEXT_MAP[merit.state.name];

  return (
    <Badge
      color={color}
      testProps={{
        elementId: merit.id,
        elementName: "MeritStatusBadge",
        screenName: route.name,
      }}
      text={text}
    />
  );
};

export { MeritStatusBadge };
