import { Body, Heading, Link, useTheme } from "@merit/frontend-components";
import { HELP } from "@src/utils/constants/urls";
import { Image, StyleSheet, View } from "react-native";
import { openExternalLink } from "@src/utils";
import LoggingInImage from "@src/assets/images/logging-in.png";
import MeritLogo from "@src/assets/images/merit-logotype.png";
import type { ImageStyle, TextStyle, ViewStyle } from "react-native";

const handleHelpOnPress = () => {
  openExternalLink(HELP);
};

const LoggingIn = () => {
  const { theme } = useTheme();

  const styles = StyleSheet.create<{
    readonly header: TextStyle;
    readonly container: TextStyle;
    readonly content: ViewStyle;
    readonly headingText: TextStyle;
    readonly image: ImageStyle;
    readonly logoImage: ImageStyle;
  }>({
    container: {
      alignItems: "center",
      backgroundColor: theme.colors.background.white,
      flex: 1,
      justifyContent: "center",
      padding: theme.spacing.xxl,
      textAlign: "center",
    },
    content: {
      alignItems: "center",
      flex: 1,
      justifyContent: "center",
      maxWidth: 440,
    },
    header: {
      alignItems: "center",
      height: 50,
      justifyContent: "center",
    },
    headingText: {
      marginBottom: theme.spacing.l,
    },
    image: {
      height: 160,
      marginBottom: 64, // Matching designs, not in theme
      width: 160,
    },
    logoImage: {
      height: 32,
      width: 102,
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Image source={MeritLogo} style={styles.logoImage} />
      </View>
      <View style={styles.content}>
        <Image source={LoggingInImage} style={styles.image} />
        <Heading level="1" style={styles.headingText}>
          Log in using the pop-up
        </Heading>
        <Body>
          Check your pop-up blocker settings if you cannot find the login screen or go back and try
          again. Click <Link onPress={handleHelpOnPress}>here</Link> for help.
        </Body>
      </View>
    </View>
  );
};

export { LoggingIn };
