import { Heading, LabeledText, useTheme } from "@merit/frontend-components";
import { MeritStatusBadge } from "@src/components/MeritStatusBadge";
import { OrgLogo } from "@src/components";
import { StyleSheet, View } from "react-native";
import { useRoute } from "@react-navigation/native";
import type { MeritSummaryProps } from "./types";
import type { ViewStyle } from "react-native";

const ISSUER_IMAGE_SIZE = 48;

export const MeritSummary = ({ merit }: MeritSummaryProps) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly issuerImageContainer: ViewStyle;
    readonly labeledText: ViewStyle;
    readonly row: ViewStyle;
    readonly badgeWrapper: ViewStyle;
    readonly meritTitleContainer: ViewStyle;
  }>({
    badgeWrapper: {
      marginBottom: theme.spacing.s,
    },
    container: {
      backgroundColor: theme.colors.background.white,
      borderColor: theme.colors.border.default,
      borderRadius: theme.borderRadii.s,
      marginBottom: theme.spacing.m,
    },
    issuerImageContainer: {
      borderRadius: theme.borderRadii.m,
      height: ISSUER_IMAGE_SIZE,
      marginLeft: theme.spacing.m,
      overflow: "hidden",
      width: ISSUER_IMAGE_SIZE,
    },
    labeledText: {
      flex: 1,
    },
    meritTitleContainer: {
      marginBottom: theme.spacing.l,
    },
    row: {
      flexDirection: "row",
      paddingVertical: theme.spacing.l / 2,
    },
  });

  const route = useRoute();

  return (
    <View style={styles.container}>
      <View style={styles.badgeWrapper}>
        <MeritStatusBadge merit={merit} />
      </View>

      <View style={styles.meritTitleContainer}>
        {/* TODO: get style prop in Heading? Figma specifies text size 24 but heading lvl1 is size 20 */}
        <Heading
          level="1"
          testProps={{
            elementId: "meritName",
            elementName: "MeritDetails",
            screenName: route.name,
          }}
        >
          {merit.name}
        </Heading>
      </View>
      <View style={styles.row}>
        <LabeledText label="" style={styles.labeledText} text={merit.transformedFields.orgName} />
        <View style={styles.issuerImageContainer}>
          <OrgLogo merit={merit} />
        </View>
      </View>
      <View style={styles.row}>
        <LabeledText
          label="Issued to"
          style={styles.labeledText}
          testProps={{
            elementName: "IssuedTo",
            screenName: route.name,
          }}
          text={merit.transformedFields.recipientFullName}
          textProps={{ numberOfLines: 1 }}
        />
      </View>
      <View style={styles.row}>
        <LabeledText
          label="Issued on"
          style={styles.labeledText}
          testProps={{
            elementName: "IssuedOn",
            screenName: route.name,
          }}
          text={merit.transformedFields.issuedOn}
        />
      </View>
    </View>
  );
};
