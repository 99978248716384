import { Header } from "../Header";
import { Icon } from "@merit/frontend-components";
import { useNavigation } from "@src/hooks";
import type { HeaderProps } from "../Header";
import type { VFC } from "react";

export type HeaderGoBackProps = Omit<HeaderProps, "leftElement" | "leftOnPress">;

export const HeaderGoBack: VFC<HeaderGoBackProps> = props => {
  const navigation = useNavigation();

  const handlePress = () => {
    navigation.goBackOrGoHome();
  };

  return (
    <Header
      leftElement={<Icon alt="Go back" name="arrowBackwardMediumAction" />}
      leftOnPress={handlePress}
      {...props}
    />
  );
};
