import { Body, Heading, useTheme } from "@merit/frontend-components";
import { StyleSheet } from "react-native";
import { useAccountMerit } from "@src/api/issuance";
import { useGetTestProps } from "@src/hooks";

const OnboardingMeritsFolioHeading = () => {
  const { theme } = useTheme();
  const getTestProps = useGetTestProps();
  const { data: accountMerit } = useAccountMerit();
  const styles = StyleSheet.create({
    headingText: {
      marginBottom: theme.spacing.xl,
      textAlign: "center",
    },
  });

  return (
    <>
      <Heading
        bold
        level="1"
        style={styles.headingText}
        {...getTestProps({ elementId: "welcomeText", elementName: "OnboardingMeritsFolioHeading" })}
      >
        Welcome,{" "}
        {accountMerit?.fieldMap["Chosen Name"] ?? accountMerit?.fieldMap["First Name"] ?? ""}!
      </Heading>
      <Body
        size="l"
        style={styles.headingText}
        {...getTestProps({ elementId: "bodyText", elementName: "OnboardingMeritsFolioHeading" })}
      >
        Accept your folio to get started.
      </Body>
    </>
  );
};

export { OnboardingMeritsFolioHeading };
