import { Constants, getChosenNameFromContainerFields, openExternalLink } from "@src/utils";
import { Home, MeritsScreen, Settings } from "@src/screens";
import { Icon } from "@merit/frontend-components";
import { LOGOUT } from "@src/utils/constants/text";
import { createSideBarNavigator } from "@src/components/SideBarNavigator";
import { useAccountMerit } from "@src/api/issuance";
import { useFeatureFlags, useLogout, useMeritAuth0 } from "@src/hooks";
import type { ScreenParamList } from "../types";

export type SideBarParamList = Pick<
  ScreenParamList,
  "Help" | "Home" | "LoggingOut" | "Merits" | "Settings"
> & {
  readonly Icons: undefined;
};

const SideBar = createSideBarNavigator<SideBarParamList>();

export const SideBarNavigator = () => {
  const { data: accountMerit } = useAccountMerit();
  const { user } = useMeritAuth0();
  const logout = useLogout();
  const { data: featureFlags } = useFeatureFlags();

  const fullName =
    accountMerit?.fields === undefined
      ? user?.nickname
      : getChosenNameFromContainerFields(accountMerit.fields);

  return (
    <SideBar.Navigator
      buttons={[
        {
          icon: <Icon name="helpOutlinedMediumDefault" />,
          id: "help",
          onPress: () => {
            openExternalLink(Constants.URLs.HELP);
          },
          order: "before",
          section: "bottom",
          text: "Help",
        },
        {
          icon: <Icon name="logoutMediumDefault" />,
          id: "logout",
          onPress: logout,
          order: "after",
          section: "bottom",
          text: LOGOUT,
        },
      ]}
      headerHeight={Constants.Sizes.HEADER_HEIGHT}
      headerIcon={<Icon name="avatarCircleMedium" />}
      headerText={fullName ?? ""}
      initialRouteName="Home"
    >
      <SideBar.Screen
        component={Home}
        name="Home"
        options={{ icon: <Icon name="homeMediumDefault" /> }}
      />
      <SideBar.Screen
        component={MeritsScreen}
        name="Merits"
        options={{ icon: <Icon name="meritOutlinedMedium" /> }}
      />
      {featureFlags?.showSettings === true ? (
        <SideBar.Screen
          component={Settings}
          name="Settings"
          options={{ icon: <Icon name="gearOutlinedMedium" />, section: "bottom" }}
        />
      ) : null}
    </SideBar.Navigator>
  );
};
