import { Body, useTheme } from "@merit/frontend-components";
import { MeritMarkdownRenderer } from "./MeritMarkdownRenderer";
import { StyleSheet, View } from "react-native";
import { useGetTestProps } from "@src/hooks";
import Markdown from "react-native-marked";
import type { StyleProp, ViewStyle } from "react-native";

export type LabeledMarkdownProps = {
  readonly label: string;
  readonly markdown: string;
  readonly style?: StyleProp<ViewStyle>;
  readonly elementId?: string;
};

const renderer = new MeritMarkdownRenderer();

export const LabeledMarkdown = ({ elementId, label, markdown, style }: LabeledMarkdownProps) => {
  const { theme } = useTheme();

  const SEMIBOLD_FONT_STYLE = {
    fontFamily: "ProximaNovaSemiBold",
    fontWeight: theme.fontWeights.semiBold,
  };

  const getTestProps = useGetTestProps();
  const styles = StyleSheet.create({
    label: {
      ...theme.fontSizes.s,
      marginBottom: theme.spacing.xs,
    },
  });

  return (
    <View
      style={style}
      {...getTestProps({
        elementId,
        elementName: "LabeledMarkdown",
      })}
    >
      <Body style={styles.label}>{label}</Body>
      <Markdown
        renderer={renderer}
        // TODO (PE-719): unrevert these hardcoded values
        styles={{
          h1: {
            fontSize: 28,
            lineHeight: 32,
            ...SEMIBOLD_FONT_STYLE,
          },
          h2: {
            fontFamily: theme.fonts.normal,
            fontSize: 26,
            lineHeight: 32,
          },
          h3: {
            fontFamily: theme.fonts.normal,
            fontSize: 20,
            lineHeight: 24,
          },
          h4: {
            fontSize: 16,
            lineHeight: 24,
            ...SEMIBOLD_FONT_STYLE,
          },
          h5: {
            fontSize: 14,
            lineHeight: 20,
            ...SEMIBOLD_FONT_STYLE,
          },
          h6: {
            fontFamily: theme.fonts.normal,
            fontSize: 14,
            lineHeight: 20,
          },
          link: {},
          strong: {
            ...theme.fontSizes.s,
            ...SEMIBOLD_FONT_STYLE,
          },
          text: {
            ...theme.fontSizes.s,
            fontFamily: theme.fonts.normal,
          },
        }}
        theme={{
          colors: {
            background: theme.colors.background.white,
            border: theme.colors.border.default,
            code: theme.colors.text.default,
            link: theme.colors.text.link,
            text: theme.colors.text.default,
          },
          spacing: {
            l: theme.spacing.l,
            m: theme.spacing.m,
            s: theme.spacing.s,
            xs: theme.spacing.xs,
          },
        }}
        value={markdown}
      />
    </View>
  );
};
