import { Body, Heading, Link, useTheme } from "@merit/frontend-components";
import { StyleSheet, View } from "react-native";
import { useEntityMerge } from "./hooks";
import { useRoute } from "@src/hooks";
import type { TextStyle } from "react-native";

const EntityMerge = () => {
  const { theme } = useTheme();
  const route = useRoute();
  const { mergeEntities } = useEntityMerge();

  const styles = StyleSheet.create<{
    readonly entityMergeHeading: TextStyle;
  }>({
    entityMergeHeading: {
      ...theme.fontSizes.l,
    },
  });

  return (
    <View>
      <Heading
        level="1"
        style={styles.entityMergeHeading}
        testProps={{
          elementName: "EntityMergeHeading",
          screenName: route.name,
        }}
      >
        Merge accounts
      </Heading>
      <Body
        testProps={{
          elementName: "EntityMergeBody",
          screenName: route.name,
        }}
      >
        Merge accounts will transfer all merits into your Merit account. This action cannot be
        undone.{" "}
        <Link
          onPress={() => {
            mergeEntities();
          }}
          testProps={{
            elementName: "EntityMergeButton",
            screenName: route.name,
          }}
        >
          Merge accounts
        </Link>
      </Body>
    </View>
  );
};

export { EntityMerge };
