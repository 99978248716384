import type { MeritFilter } from "./types";

type ContainsText = (text: string) => MeritFilter;

export const containsText: ContainsText = searchText => merit => {
  // eslint-disable-next-line no-underscore-dangle
  const _searchText = searchText.toLocaleLowerCase().trim();

  if (merit.name?.toLocaleLowerCase().includes(_searchText) ?? false) {
    return true;
  }

  if (merit.fieldMap["Issuing Org Name"].toLocaleLowerCase().includes(_searchText)) {
    return true;
  }

  if (merit.transformedFields.orgName.toLocaleLowerCase().includes(_searchText)) {
    return true;
  }

  return false;
};
