import { Body } from "@merit/frontend-components";
import type { StyleProp, ViewStyle } from "react-native";

type CopyrightProps = {
  readonly style?: StyleProp<ViewStyle>;
};

export const Copyright = ({ style }: CopyrightProps) => (
  <Body size="s" style={style}>
    Merit International © 2024
  </Body>
);
