import { Body, Button, Link, Modal, useTheme } from "@merit/frontend-components";
import { Constants, openExternalLink } from "@src/utils";
import { RejectMeritForm } from "@src/components";
import { StyleSheet, View } from "react-native";
import { Text } from "@src/utils/constants";
import { isAdminMerit, useAcceptMerit, useMerit } from "@src/api/issuance";
import { useLayoutType, useRoute } from "@src/hooks";
import { useState } from "react";
import type { Merit } from "@src/api/issuance";

const helpOnPress = () => {
  openExternalLink(Constants.URLs.HELP);
};

type OnboardingMeritsFooterProps = {
  readonly meritId: Merit["id"];
  readonly onPressAcceptAdminMerit?: (meritId: Merit["id"]) => void;
};

const OnboardingMeritsFooter = ({
  meritId,
  onPressAcceptAdminMerit,
}: OnboardingMeritsFooterProps) => {
  const [isRejectMeritModalOpen, setIsRejectMeritModalOpen] = useState(false);

  const { theme } = useTheme();
  const acceptMerit = useAcceptMerit();
  const layoutType = useLayoutType();
  const { name: routeName } = useRoute();
  const merit = useMerit(meritId);

  const styles = StyleSheet.create({
    acceptButtonWrapper: {
      flex: 1,
      width: 200,
    },
    buttons: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "center",
      marginBottom: layoutType === "desktop" ? theme.spacing.xxl : theme.spacing.l,
    },
    container: {
      ...theme.elevations.depth3,
      alignItems: "center",
      backgroundColor: theme.colors.background.white,
      padding: layoutType === "desktop" ? theme.spacing.xxl : theme.spacing.l,
    },
    rejectButtonWrapper: {
      marginRight: layoutType === "desktop" ? theme.spacing.xxl : theme.spacing.l,
    },
  });

  const closeRejectMeritModal = () => {
    setIsRejectMeritModalOpen(false);
  };

  const onAcceptPress = async () => {
    if (
      merit !== undefined &&
      isAdminMerit(merit) &&
      merit.transformedFields.orgName !== Text.MERIT_COMPANY_NAME
    ) {
      // special case where an admin merit will be accepted at the same time the associated account folio is accepted
      onPressAcceptAdminMerit?.(meritId);
    } else {
      await acceptMerit.mutateAsync(meritId);
    }
  };

  const onRejectPress = () => {
    setIsRejectMeritModalOpen(true);
  };

  const handleReject = () => {
    closeRejectMeritModal();
  };

  if (merit === undefined) {
    return null;
  }

  return (
    <View style={styles.container}>
      <View style={styles.buttons}>
        <View style={styles.rejectButtonWrapper}>
          <Button
            onPress={onRejectPress}
            testProps={{
              elementId: "rejectButton",
              elementName: "OnboardingMeritsFooter",
              screenName: routeName,
            }}
            text="Reject"
            type="secondary"
          />
        </View>
        <View style={styles.acceptButtonWrapper}>
          <Button
            onPress={onAcceptPress}
            testProps={{
              elementId: "acceptButton",
              elementName: "OnboardingMeritsFooter",
              screenName: routeName,
            }}
            text="Accept"
            type="primary"
          />
        </View>
      </View>
      <Body
        size="s"
        testProps={{
          elementId: "learnMoreText",
          elementName: "OnboardingMeritsFooter",
          screenName: routeName,
        }}
      >
        <Link
          onPress={helpOnPress}
          testProps={{
            elementId: "helpTextLink",
            elementName: "OnboardingMeritsFooter",
            screenName: routeName,
          }}
        >
          Learn more
        </Link>{" "}
        about Merit and why you received this
      </Body>
      {isRejectMeritModalOpen ? (
        <Modal
          maxWidth={Constants.Sizes.MODAL}
          onClose={closeRejectMeritModal}
          title="Reject this merit"
          titleIconName="warningMediumCritical"
          width="100%"
        >
          <RejectMeritForm merit={merit} onCancel={closeRejectMeritModal} onReject={handleReject} />
        </Modal>
      ) : null}
    </View>
  );
};

export { OnboardingMeritsFooter };
