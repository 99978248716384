/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { LabeledMarkdown } from "../LabeledMarkdown";
import { LabeledText, useTheme } from "@merit/frontend-components";
import { Masks, formatWithMask } from "react-native-mask-input";
import { StyleSheet, View } from "react-native";
import { parseActivityTemplateFieldValue } from "@src/utils";
import { useRoute } from "@src/hooks";
import type { ContainerField } from "@merit/issuance-client";
import type { ViewStyle } from "react-native";

type ContainerFieldsListProps = {
  readonly fields: readonly ContainerField[];
};

export const ContainerFieldsList = ({ fields }: ContainerFieldsListProps) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly detailContainer: ViewStyle;
  }>({
    container: {
      backgroundColor: theme.colors.background.white,
      paddingHorizontal: theme.spacing.l,
      paddingVertical: theme.spacing.m,
    },
    detailContainer: {
      marginVertical: theme.spacing.m,
    },
  });

  const { name: routeName } = useRoute();

  const renderItem = (item: ContainerField) => {
    const itemLabel = item.name ?? `Unknown field name`;
    if (item.value === undefined) {
      return null;
    }

    if (item.fieldKind?.fieldType === "Markdown") {
      return (
        <LabeledMarkdown
          elementId={item.templateFieldID}
          label={itemLabel}
          markdown={item.value}
          style={styles.detailContainer}
        />
      );
    }

    if (item.fieldKind?.fieldType === "Blob") {
      // do not show blob field values
      return null;
    }

    if (item.fieldKind?.fieldType === "JSON") {
      if (item.name === "Alternate Emails") {
        // Primary email and alternates are displayed on settings page already
        return null;
      }
      if (typeof item.value === "string" && parseActivityTemplateFieldValue(item.value).success) {
        // do not show raw Activity JSON in details tab
        return null;
      }
    }

    // eslint-disable-next-line functional/no-let
    let itemText = item.value;
    if (item.fieldKind?.fieldType === "PhoneNumber" && item.value.length === 10) {
      itemText = formatWithMask({ mask: Masks.USA_PHONE, text: item.value }).masked;
    }

    return (
      <LabeledText
        label={itemLabel}
        style={styles.detailContainer}
        testProps={{
          elementId: "header",
          elementName: `${item.name}`,
          screenName: routeName,
        }}
        text={itemText}
      />
    );
  };

  return (
    <View style={styles.container}>
      {fields.map(field => {
        const renderedItem = renderItem(field);
        if (renderedItem === null) {
          return null;
        }

        return <View key={field.templateFieldID ?? ""}>{renderedItem}</View>;
      })}
    </View>
  );
};
