import { Body, Heading, useTheme } from "@merit/frontend-components";
import { StyleSheet } from "react-native";
import { useAccountMerit } from "@src/api/issuance";
import { useLayoutType, useRoute } from "@src/hooks";

type OnboardingMeritsHeadingProps = {
  readonly pendingMeritsCount: number;
};

const OnboardingMeritsHeading = ({ pendingMeritsCount }: OnboardingMeritsHeadingProps) => {
  const { theme } = useTheme();
  const { data: accountMerit } = useAccountMerit();
  const layoutType = useLayoutType();
  const { name: routeName } = useRoute();
  const styles = StyleSheet.create({
    headingText: {
      marginBottom: layoutType === "desktop" ? theme.spacing.xxl : theme.spacing.l,
      textAlign: "center",
    },
  });

  const pluralizedText = pendingMeritsCount > 1 ? "merits" : "merit";
  const bodyText = `Accept your ${pluralizedText} to get started.`;

  return (
    <>
      <Heading
        bold
        level="1"
        style={styles.headingText}
        testProps={{
          elementId: "welcomeText",
          elementName: "OnboardingMeritsHeading",
          screenName: routeName,
        }}
      >
        Welcome,{" "}
        {accountMerit?.fieldMap["Chosen Name"] ?? accountMerit?.fieldMap["First Name"] ?? ""}!
      </Heading>
      <Body
        size="l"
        style={styles.headingText}
        testProps={{
          elementId: "bodyText",
          elementName: "OnboardingMeritsHeading",
          screenName: routeName,
        }}
      >
        {bodyText}
      </Body>
    </>
  );
};

export { OnboardingMeritsHeading };
