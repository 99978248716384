import type { Merit } from "../types";

export const getIssuedOn = (merit: PickPartial<Merit, "transformedFields">): string =>
  merit.createdAt === undefined
    ? "Unknown issue date"
    : Intl.DateTimeFormat("default", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }).format(new Date(merit.createdAt));
