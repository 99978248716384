import { type Activity, ActivityTemplateFieldValueSchema } from "@src/api/issuance/types/Activity";
import { Log } from "@src/utils";
import type { ActivityTemplateFieldValue } from "@src/api/issuance/types";
import type { Container, ContainerField } from "@merit/issuance-client";

export const getFirstNameFromContainerFields = (fields: readonly ContainerField[]) =>
  fields.find(field => field.name?.toLocaleLowerCase() === "first name")?.value;

export const getLastNameFromContainerFields = (fields: readonly ContainerField[]) =>
  fields.find(field => field.name?.toLocaleLowerCase() === "last name")?.value;

export const getChosenNameFromContainerFields = (fields: readonly ContainerField[]) =>
  fields.find(field => field.name?.toLocaleLowerCase() === "chosen name")?.value;

export const getPhoneNumberFromContainerFields = (fields: readonly ContainerField[]) =>
  fields.find(field => field.name?.toLocaleLowerCase() === "phone number")?.value;

export const getCoppaAcknowledgedFromContainerFields = (fields: readonly ContainerField[]) =>
  fields.find(field => field.name?.toLocaleLowerCase() === "coppa")?.value === "true";

export const getFilesAndMediaFieldsFromContainerFields = (fields: readonly ContainerField[]) =>
  fields.filter(field => field.fieldKind?.fieldType === "Blob");

export const getPrimaryEmailFromContainerFields = (fields: readonly ContainerField[]) => {
  const allEmails = fields.filter(field => field.fieldKind?.fieldType === "Email");

  return allEmails[0].value; // Use first as primary for now, check for "contact" boolean in future
};

export const parseActivityTemplateFieldValue = (value: string) =>
  ActivityTemplateFieldValueSchema.safeParse(value);

export const getActivityTemplateFieldValueFromContainerFields = (
  fields: readonly ContainerField[],
  containerId: Container["id"] = "not provided"
) => {
  // strategy is to "ducktype" any JSON field kind values and return one that has all keys expected for an Activity
  const matchingJsonTemplateFields = fields
    .map(field => {
      if (field.fieldKind?.fieldType !== "JSON" || field.value === undefined) {
        return undefined;
      }
      const parseResults = parseActivityTemplateFieldValue(field.value);

      return parseResults.success ? parseResults.data : undefined;
    })
    .filter(x => x !== undefined);

  if (matchingJsonTemplateFields.length > 1) {
    // assumption is only one should exist, warn otherwise because that means product has changed
    Log.warn("more than one possible activity JSON template field found", { containerId });
  }

  return matchingJsonTemplateFields[0];
};

type GetSectionsFromCheckInDetailsReturn = readonly {
  readonly data: readonly Activity[];
  readonly title: string;
}[];
export const getSectionsFromActivityTemplateFieldValue = (
  activityTemplateFieldValue: ActivityTemplateFieldValue
): GetSectionsFromCheckInDetailsReturn => {
  const { checkInDetails } = activityTemplateFieldValue;
  const groupedByActivityName: Record<string, readonly Activity[]> = checkInDetails.reduce<
    Record<string, readonly Activity[]>
  >((acc, checkInDetail) => {
    const { activityName } = checkInDetail;
    const updated = [...(Object.hasOwn(acc, activityName) ? acc[activityName] : []), checkInDetail];

    return {
      ...acc,
      [activityName]: updated,
    };
  }, {});

  return Object.entries(groupedByActivityName).map(([activityName, checkIns]) => ({
    data: checkIns,
    title: activityName,
  }));
};
