// Copyright 2023 Merit International Inc. All Rights Reserved.
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import AsyncStorage from "@react-native-async-storage/async-storage";

type OnboardingStep = "COMPLETE" | "LOADING" | "ONBOARDING_MERITS" | "REGISTRATION";

type OnboardingStepState = {
  readonly onboardingStep: OnboardingStep;
  readonly setOnboardingStep: (onboardingStep: OnboardingStep) => void;
};

export const useOnboardingStore = create<OnboardingStepState>()(
  persist(
    set => ({
      onboardingStep: "LOADING",
      setOnboardingStep: (onboardingStep: OnboardingStep) => {
        set(_ => ({
          onboardingStep,
        }));
      },
    }),
    {
      name: "onboarding",
      storage: createJSONStorage(() => AsyncStorage),
    }
  )
);
