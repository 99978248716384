import { Body, Heading, useTheme } from "@merit/frontend-components";
import { Icon } from "@src/components";
import { StyleSheet, View } from "react-native";
import { useGetTestProps, useRoute } from "@src/hooks";
import { withOpacity } from "@src/utils";
import type { ViewStyle } from "react-native";

export const EmptyNotification = () => {
  const getTestProps = useGetTestProps();
  const route = useRoute();
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly heading: ViewStyle;
    readonly iconContainer: ViewStyle;
  }>({
    container: {
      alignItems: "center",
      flex: 1,
      flexDirection: "row",
      paddingLeft: theme.spacing.xl,
    },
    heading: {
      marginBottom: theme.spacing.s,
    },
    iconContainer: {
      alignItems: "center",
      backgroundColor: withOpacity(theme.colors.brand.oceanBlue, 0.1),
      borderRadius: 35,
      height: 70,
      justifyContent: "center",
      marginRight: theme.spacing.m,
      width: 70,
    },
  });

  return (
    <View
      style={styles.container}
      {...getTestProps({
        elementName: "EmptyNotification",
      })}
    >
      <View style={styles.iconContainer}>
        <Icon color={theme.colors.brand.oceanBlue} name="notifications" size={48} />
      </View>
      <View>
        <Heading
          bold
          level="3"
          style={styles.heading}
          testProps={{
            elementId: "heading",
            elementName: "EmptyNotification",
            screenName: route.name,
          }}
        >
          You're looking good
        </Heading>
        <Body
          testProps={{
            elementId: "description",
            elementName: "EmptyNotification",
            screenName: route.name,
          }}
        >
          No new notifications
        </Body>
      </View>
    </View>
  );
};
