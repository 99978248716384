import { Log, findAndMerge } from "@src/utils";
import { PatchContainerStateEnum } from "@merit/issuance-client";
import { capitalize } from "@src/utils/string";
import { destructiveActionTexts } from "@src/components";
import { useAlerts } from "@src/hooks";
import { useContainersApi } from "./useContainersApi";
import { useMerits, useMeritsQueryKey } from "./useMerits";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { Merit } from "./types";

export const useRejectMerit = () => {
  const { api: containersApi } = useContainersApi();
  const { sendAlert } = useAlerts();
  const { refetch } = useMerits();
  const queryKey = useMeritsQueryKey();
  const queryClient = useQueryClient();

  const verbTenses = {
    past: destructiveActionTexts.rejectMerit.verbPastTense,
    progressive: destructiveActionTexts.rejectMerit.verbProgressiveTense,
  };

  return useMutation({
    mutationFn: (meritId: Merit["id"]) =>
      containersApi.patchContainer({
        containerID: meritId,
        properties: {
          state: PatchContainerStateEnum.Rejected,
        },
      }),
    onError: (err, meritId) => {
      // We could potentially roll back here. Currently we just refetch onSettled.
      Log.error(`Error ${verbTenses.progressive} merit ${meritId} ${String(err)}`);
      sendAlert({
        id: "useRejectMerit-Error",
        text1: `Error ${verbTenses.progressive} your merit`,
        text2: String(err),
        type: "error",
      });
    },
    onMutate: async meritId => {
      sendAlert({
        id: "useRejectMerit-Rejecting",
        text1: `${capitalize(verbTenses.progressive)}…`,
        type: "info",
      });

      // Cancel any outgoing refetches, so they don't overwrite our optimistic update
      await queryClient.cancelQueries({ queryKey });

      // Snapshot the previous value
      const merits: readonly Merit[] | undefined = queryClient.getQueryData(queryKey);

      if (merits === undefined) {
        return undefined;
      }

      const newMerits = findAndMerge(merits, m => m.id === meritId, {
        state: { name: PatchContainerStateEnum.Rejected, occurredAt: new Date().toISOString() },
      });

      if (newMerits === undefined) {
        return undefined;
      }

      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, newMerits);

      // Return a context object with the snapshotted value
      return { merits };
    },
    onSettled: () => {
      refetch();
    },
    onSuccess: () => {
      sendAlert({
        id: "useRejectMerit-Success",
        text1: `Your Merit has been ${verbTenses.past}`,
        type: "success",
      });
    },
  });
};
