import { Body, Heading, LineSeparator, useTheme } from "@merit/frontend-components";
import { FlatList, Pressable, StyleSheet, View } from "react-native";
import { MeritListItem } from "../MeritListItem";
import { MeritListItemSkeleton } from "../MeritListItemSkeleton";
import { byAuthorizedAt, hasState } from "@src/utils";
import { range } from "lodash";
import { useGetTestProps, useNavigation, useRoute } from "@src/hooks";
import { useMemo } from "react";
import { useMerits } from "@src/api/issuance";
import type { FC } from "react";
import type { Merit } from "@src/api/issuance";
import type { ViewStyle } from "react-native";

const NUM_ROWS = 5;

export const MyMerits: FC = () => {
  const getTestProps = useGetTestProps();
  const { data: merits } = useMerits();
  const route = useRoute();
  const { theme } = useTheme();
  const navigation = useNavigation();

  const displayedMerits = useMemo(
    () =>
      merits
        ?.filter(hasState(["accepted"]))
        .sort(byAuthorizedAt)
        .slice(0, NUM_ROWS),
    [merits]
  );

  const styles = StyleSheet.create<{
    readonly heading: ViewStyle;
    readonly viewMorePressable: ViewStyle;
  }>({
    heading: {
      paddingVertical: theme.spacing.xxl,
    },
    viewMorePressable: {
      marginLeft: theme.spacing.xxl,
      paddingVertical: theme.spacing.m,
    },
  });

  const handleViewMorePress = () => {
    navigation.navigate("Merits");
  };

  const handleMeritPress = (merit: Merit) => {
    navigation.navigate("MeritDetails", { meritId: merit.id });
  };

  if (merits === undefined) {
    return (
      <View>
        <FlatList
          ItemSeparatorComponent={() => <LineSeparator />}
          ListFooterComponent={() => (
            <Pressable
              onPress={handleViewMorePress}
              style={styles.viewMorePressable}
              {...getTestProps({
                elementId: "viewMorePressable",
                elementName: "MyMerits",
              })}
            >
              <Body color={theme.colors.text.link}>View All</Body>
            </Pressable>
          )}
          ListHeaderComponent={() => (
            <>
              <View style={styles.heading}>
                <Heading
                  bold
                  level="2"
                  testProps={{
                    elementId: "heading",
                    elementName: "MyMerits",
                    screenName: route.name,
                  }}
                >
                  My Merits
                </Heading>
              </View>
              <LineSeparator />
            </>
          )}
          data={range(NUM_ROWS)}
          keyExtractor={item => String(item)}
          renderItem={() => <MeritListItemSkeleton />}
        />
      </View>
    );
  }

  return (
    <View>
      <FlatList
        ItemSeparatorComponent={() => <LineSeparator />}
        ListFooterComponent={() => (
          <Pressable
            onPress={handleViewMorePress}
            style={styles.viewMorePressable}
            {...getTestProps({
              elementId: "viewMorePressable",
              elementName: "MyMerits",
            })}
          >
            <Body color={theme.colors.text.link} size="l">
              View more
            </Body>
          </Pressable>
        )}
        ListHeaderComponent={() => (
          <>
            <View style={styles.heading}>
              <Heading
                bold
                level="3"
                {...getTestProps({ elementId: "heading", elementName: "MyMerits" })}
              >
                My Merits
              </Heading>
            </View>
            <LineSeparator />
          </>
        )}
        data={displayedMerits}
        keyExtractor={merit => merit.id}
        renderItem={({ item }) => (
          <MeritListItem
            merit={item}
            onPress={() => {
              handleMeritPress(item);
            }}
          />
        )}
      />
    </View>
  );
};
