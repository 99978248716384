import { Body, useTheme } from "@merit/frontend-components";
import { FilesAndMediaBlobFieldListItem } from "@src/components";
import { StyleSheet, View } from "react-native";
import { getFilesAndMediaFieldsFromContainerFields } from "@src/utils";
import { useMemo } from "react";
import type { ContainerField } from "@merit/issuance-client";
import type { Folio } from "@src/api/issuance";
import type { TextStyle, ViewStyle } from "react-native";

type FolioTabFilesAndMediaProps = {
  readonly folio: Folio;
};

export const FolioTabFilesAndMedia = ({ folio }: FolioTabFilesAndMediaProps) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly listContainer: ViewStyle;
    readonly text: TextStyle;
    readonly itemSeparator: ViewStyle;
  }>({
    container: {
      backgroundColor: theme.colors.background.white,
      padding: theme.spacing.l,
    },
    itemSeparator: {
      marginBottom: theme.spacing.s,
    },
    listContainer: {
      backgroundColor: theme.colors.background.white,
      padding: theme.spacing.l,
    },
    text: {
      color: theme.colors.text.default,
    },
  });

  const filesAndMediaFieldsWithValues = useMemo<readonly ContainerField[]>(
    () =>
      getFilesAndMediaFieldsFromContainerFields(folio.fields ?? []).filter(
        field => (field.value?.length ?? 0) > 0
      ),
    [folio]
  );

  if (filesAndMediaFieldsWithValues.length === 0) {
    return (
      <View style={styles.container}>
        <Body style={styles.text}>No media fields exist on this folio</Body>
      </View>
    );
  }

  return (
    <View style={styles.listContainer}>
      {filesAndMediaFieldsWithValues.map((field, index) => (
        <View key={field.templateFieldID ?? field.name ?? ""}>
          {index > 0 && <View style={styles.itemSeparator} />}
          <FilesAndMediaBlobFieldListItem containerField={field} />
        </View>
      ))}
    </View>
  );
};
