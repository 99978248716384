import { useAccountMerit } from "@src/api/issuance";

const useSettings = () => {
  const { data: accountMerit, status } = useAccountMerit();

  if (status !== "success") {
    return undefined;
  }

  const legalName = `${accountMerit.fieldMap["First Name"]} ${accountMerit.fieldMap["Last Name"]}`;
  const displayName = accountMerit.fieldMap["Chosen Name"] ?? accountMerit.fieldMap["First Name"];

  const { alternates = [], primary = accountMerit.fieldMap.Email } =
    accountMerit.fieldMap["Alternate Emails"] ?? {};

  const additionalEmails = alternates.map(email => ({
    email,
    isPrimary: false,
  }));

  const emails = [{ email: primary, isPrimary: true }, ...additionalEmails];

  return { displayName, emails, legalName };
};

export { useSettings };
