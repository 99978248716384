import { Log } from "@src/utils";

export const withOpacity = (color: string, opacity: number): string => {
  const pattern = /rgba\(([0-9]{1,3},\s?){2,3}[0-9]{1,3}\)/u;

  if (!pattern.test(color)) {
    Log.warn(`color must be specified as rgb or rgba. color: ${color}`);

    return color;
  }

  if (opacity < 0 || opacity > 1) {
    Log.warn(`opacity must be between 0 and 1. opacity: ${opacity}`);

    return color;
  }

  const numbers = color.split("(")[1].split(")")[0].split(",");

  if (numbers.length === 3) {
    const numbersWithOpacity = numbers.concat(String(opacity));

    return `rgba(${numbersWithOpacity.join(",")})`;
  }

  const numbersWithOpacity = numbers.slice(0, 3).concat([String(opacity)]);

  return `rgba(${numbersWithOpacity.join(",")})`;
};
