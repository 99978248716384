import {
  Body,
  Button,
  Checkbox,
  Heading,
  Icon,
  TextInput,
  useTheme,
} from "@merit/frontend-components";
import { Image, Pressable, StyleSheet, View } from "react-native";
import {
  Log,
  getChosenNameFromContainerFields,
  getFirstNameFromContainerFields,
  getLastNameFromContainerFields,
  getPhoneNumberFromContainerFields,
} from "@src/utils";
import { MERIT_LOGOTYPE } from "@src/utils/constants/sizes";
import { Masks, useMaskedInputProps } from "react-native-mask-input";
import { PatchContainerStateEnum } from "@merit/issuance-client";
import { ZodError, z } from "zod";
import { isValidPhoneNumber, parsePhoneNumberWithError } from "libphonenumber-js";
import { useAcceptMerit, useAccountMerit } from "@src/api/issuance";
import { useEffect, useMemo, useState } from "react";
import { useRoute } from "@react-navigation/native";
import { useUpdateAccountMerit } from "@src/api/person-experience-backend";
import MeritLogotype from "@src/assets/images/merit-logotype.png";
import type { ImageStyle, TextStyle, ViewStyle } from "react-native";

const TOOLTIP_WIDTH = 200;

// see PE-748, there used to be a regex that limits the allowed characters, but was taken out
const VALIDATE_NAMES_MAX_CHARACTERS = 40;

const validateNamesBailEarly = (fieldLabel: string) => (val: string, ctx: z.RefinementCtx) => {
  if (val === "") {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      fatal: true,
      message: `Please enter your ${fieldLabel}`,
    });
  } else if (val.length > VALIDATE_NAMES_MAX_CHARACTERS) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      fatal: true,
      message: `The maximum limit is ${VALIDATE_NAMES_MAX_CHARACTERS} characters`,
    });
  }
};

const OnboardingDataSchema = z.object({
  chosenName: z.string().trim().superRefine(validateNamesBailEarly("display name")),
  firstName: z.string().trim().superRefine(validateNamesBailEarly("first name")),
  isCoppaChecked: z.literal<boolean>(true),
  lastName: z.string().trim().superRefine(validateNamesBailEarly("last name")),
  phoneNumber: z.union([
    z.literal<string>(""),
    z.custom<string>(value => {
      if (typeof value !== "string") {
        return false; // mostly to appease TS
      }
      try {
        return isValidPhoneNumber(parsePhoneNumberWithError(value, "US").formatInternational());
      } catch {
        return false;
      }
    }, "Please enter a valid phone number"),
  ]),
});

type OnboardingData = z.infer<typeof OnboardingDataSchema>;

// zod does not allow for custom validation error messages for literals, so they're here
const validationErrorMessages: Partial<Record<keyof OnboardingData, string>> = {
  isCoppaChecked: "Please confirm that you are over the age of 13",
};

type OnboardingFormProps = {
  readonly onSubmitFailure?: (error: Error) => void;
  readonly onSubmitSuccess?: () => void;
};

export const OnboardingForm = ({ onSubmitFailure, onSubmitSuccess }: OnboardingFormProps) => {
  const { theme } = useTheme();
  const [isChosenNameTooltipShown, setIsChosenNameTooltipShown] = useState(false);
  const [tooltipIconX, setTooltipIconX] = useState<number | undefined>();
  const [tooltipIconY, setTooltipIconY] = useState<number | undefined>();
  const [tooltipIconHeight, setTooltipIconHeight] = useState<number | undefined>();
  const [tooltipContainerHeight, setTooltipContainerHeight] = useState<number | undefined>();

  const styles = StyleSheet.create<{
    readonly checkboxContainer: ViewStyle;
    readonly checkboxErrorText: TextStyle;
    readonly formContainer: ViewStyle;
    readonly formFieldsContainer: ViewStyle;
    readonly headingContainer: ViewStyle;
    readonly lineSeparator: ViewStyle;
    readonly meritLogotype: ImageStyle;
    readonly textInputErrorText: TextStyle;
    readonly textInputFieldContainer: ViewStyle;
    readonly tooltipContainer: ViewStyle;
    readonly tooltipText: TextStyle;
  }>({
    checkboxContainer: {
      marginBottom: theme.spacing.xxl,
    },
    checkboxErrorText: {
      color: theme.colors.text.alert.critical,
      fontSize: theme.fontSizes.s.fontSize,
      lineHeight: theme.fontSizes.s.lineHeight,
      marginLeft: 22,
    },
    formContainer: {
      flex: 1,
      justifyContent: "center",
    },
    formFieldsContainer: {
      alignItems: "stretch",
    },
    headingContainer: {
      marginBottom: 48,
    },
    lineSeparator: {
      backgroundColor: "#DFE1E6",
      borderRadius: 2,
      height: 6,
      marginBottom: theme.spacing.xxl,
    },
    meritLogotype: {
      ...MERIT_LOGOTYPE.m,
      marginBottom: 48,
    },
    textInputErrorText: {
      color: theme.colors.text.alert.critical,
      fontSize: theme.fontSizes.s.fontSize,
      lineHeight: theme.fontSizes.s.lineHeight,
      marginLeft: 22,
    },
    textInputFieldContainer: {
      marginBottom: theme.spacing.xxl,
    },
    tooltipContainer: {
      ...theme.elevations.depth3,
      backgroundColor: theme.colors.brand.midnight,
      borderRadius: theme.borderRadii.s,
      left: (tooltipIconX ?? 0) - TOOLTIP_WIDTH - theme.spacing.s,
      opacity: isChosenNameTooltipShown ? 1 : 0, // instead of display none/flex, because otherwise it flickers when the layout gets calculated
      padding: theme.spacing.l,
      position: "absolute",
      top: (tooltipIconY ?? 0) + (tooltipIconHeight ?? 0) - (tooltipContainerHeight ?? 0),
      width: TOOLTIP_WIDTH,
      zIndex: 1,
    },
    tooltipText: {
      ...theme.fontSizes.m,
      color: theme.colors.background.white,
    },
  });

  const { data: accountMerit } = useAccountMerit();
  const updateAccountMerit = useUpdateAccountMerit();
  const acceptAccountMerit = useAcceptMerit({ showMutatingToast: false, showSuccessToast: false });
  const route = useRoute();

  const [rawFirstName, setRawFirstName] = useState("");
  const [rawLastName, setRawLastName] = useState("");
  const [rawChosenName, setRawChosenName] = useState("");
  const [rawPhoneNumber, setRawPhoneNumber] = useState("");
  const [rawIsCoppaChecked, setRawIsCoppaChecked] = useState(false);
  const [showFormErrors, setShowFormErrors] = useState(false);
  const [areFormsInitialized, setAreFormsInitialized] = useState(false);

  const isRequestInFlight = updateAccountMerit.isLoading || acceptAccountMerit.isLoading;
  const isRequestSuccessful = updateAccountMerit.isSuccess && acceptAccountMerit.isSuccess;

  const maskedInputProps = useMaskedInputProps({
    mask: Masks.USA_PHONE,
    onChangeText: (_, unmasked) => {
      setRawPhoneNumber(unmasked);
    },
    value: rawPhoneNumber,
  });

  const [parsedFormData, formErrors] = useMemo(() => {
    const onboardingData: OnboardingData = {
      chosenName: rawChosenName,
      firstName: rawFirstName,
      isCoppaChecked: rawIsCoppaChecked,
      lastName: rawLastName,
      phoneNumber: rawPhoneNumber,
    };
    try {
      const parsed = OnboardingDataSchema.parse(onboardingData);

      return [parsed, undefined];
    } catch (err) {
      if (err instanceof ZodError) {
        return [undefined, err.flatten()];
      }
      throw err;
    }
  }, [rawChosenName, rawFirstName, rawIsCoppaChecked, rawLastName, rawPhoneNumber]);

  useEffect(() => {
    // need a useEffect because the accountMerit may not be set yet on first render
    const fields = accountMerit?.fields;
    if (fields !== undefined && !areFormsInitialized) {
      setRawFirstName(getFirstNameFromContainerFields(fields) ?? "");
      setRawLastName(getLastNameFromContainerFields(fields) ?? "");
      setRawChosenName(getChosenNameFromContainerFields(fields) ?? "");
      setRawPhoneNumber(getPhoneNumberFromContainerFields(fields) ?? "");
      setAreFormsInitialized(true);
    }
  }, [accountMerit, areFormsInitialized]);

  const onPressAccept = async () => {
    setShowFormErrors(false);

    if (formErrors !== undefined) {
      setShowFormErrors(true);

      return;
    }

    if (accountMerit?.state === undefined) {
      Log.error(`Tried accepting invalid account merit with id ${accountMerit?.id ?? ""}`);
      onSubmitFailure?.(new Error("Account merit is invalid"));

      return;
    }

    const { chosenName, firstName, isCoppaChecked, lastName, phoneNumber } = parsedFormData;
    try {
      const updateAccountMeritPromise = updateAccountMerit.mutateAsync({
        chosenName,
        coppa: isCoppaChecked,
        firstName,
        lastName,
        phoneNumber,
      });

      // handle edge case for already accepted acct merit, or else API will return a non-2xx code
      const acceptAccountMeritPromise =
        accountMerit.state.name === PatchContainerStateEnum.Accepted
          ? Promise.resolve()
          : acceptAccountMerit.mutateAsync(accountMerit.id);

      await Promise.all([acceptAccountMeritPromise, updateAccountMeritPromise]);
      onSubmitSuccess?.();
    } catch (err) {
      Log.error(
        `Error submitting OnboardingForm for account merit ${accountMerit.id} ${String(err)}`
      );
      onSubmitFailure?.(err instanceof Error ? err : new Error(String(err)));
    }
  };

  const chosenNameTooltip = (
    <>
      <Pressable
        hitSlop={theme.spacing.l}
        onLayout={evt => {
          const { height, x, y } = evt.nativeEvent.layout;
          setTooltipIconX(x);
          setTooltipIconY(y);
          setTooltipIconHeight(height);
        }}
        onPress={() => {
          setIsChosenNameTooltipShown(prevValue => !prevValue);
        }}
      >
        <Icon
          name={
            isChosenNameTooltipShown
              ? "informationMediumHighlight"
              : "informationOutlinedMediumAction"
          }
        />
      </Pressable>
      {/* tried using @floating-ui/react-native here, but it seems like it doesn't like
      playing nice with a flex-direction: row element? it doesn't want to position to the left */}
      <View
        onLayout={evt => {
          setTooltipContainerHeight(evt.nativeEvent.layout.height);
        }}
        pointerEvents={isChosenNameTooltipShown ? "auto" : "none"} // needed so invisible element doesn't block presses
        style={styles.tooltipContainer}
      >
        <Body style={styles.tooltipText}>
          This is the name that will be displayed on your Merit account.
        </Body>
      </View>
    </>
  );

  return (
    <View style={styles.formContainer}>
      <View style={styles.headingContainer}>
        <Image source={MeritLogotype} style={styles.meritLogotype} />
        <Heading
          level="1"
          testProps={{
            elementId: "header",
            elementName: "OnboardingForm",
            screenName: route.name,
          }}
        >
          Complete your registration
        </Heading>
        <Body
          testProps={{
            elementId: "subHeader",
            elementName: "OnboardingForm",
            screenName: route.name,
          }}
        >
          Please enter your details below
        </Body>
      </View>
      <View style={styles.formFieldsContainer}>
        <View style={styles.textInputFieldContainer}>
          <TextInput
            disabled={!areFormsInitialized}
            label="First name *"
            leftIcon="userCircleMediumAction"
            onChangeText={text => {
              setRawFirstName(text);
            }}
            placeholder="First name"
            size="large"
            testProps={{
              elementId: "firstName",
              elementName: "OnboardingForm",
              screenName: route.name,
            }}
            value={rawFirstName}
          />
          {showFormErrors && formErrors?.fieldErrors.firstName !== undefined ? (
            <Body style={styles.textInputErrorText}>{formErrors.fieldErrors.firstName}</Body>
          ) : null}
        </View>
        <View style={styles.textInputFieldContainer}>
          <TextInput
            disabled={!areFormsInitialized}
            label="Last name *"
            leftIcon="userCircleMediumAction"
            onChangeText={text => {
              setRawLastName(text);
            }}
            placeholder="Last name"
            size="large"
            testProps={{
              elementId: "lastName",
              elementName: "OnboardingForm",
              screenName: route.name,
            }}
            value={rawLastName}
          />
          {showFormErrors && formErrors?.fieldErrors.lastName !== undefined ? (
            <Body style={styles.textInputErrorText}>{formErrors.fieldErrors.lastName}</Body>
          ) : null}
        </View>
        <View style={styles.textInputFieldContainer}>
          <TextInput
            disabled={!areFormsInitialized}
            label="Display name *"
            labelRightElement={chosenNameTooltip}
            leftIcon="documentMediumDefault"
            onChangeText={text => {
              setRawChosenName(text);
            }}
            placeholder="Display name"
            size="large"
            testProps={{
              elementId: "chosenName",
              elementName: "OnboardingForm",
              screenName: route.name,
            }}
            value={rawChosenName}
          />
          {showFormErrors && formErrors?.fieldErrors.chosenName !== undefined ? (
            <Body style={styles.textInputErrorText}>{formErrors.fieldErrors.chosenName}</Body>
          ) : null}
        </View>
        <View style={styles.textInputFieldContainer}>
          <TextInput
            disabled={!areFormsInitialized}
            keyboardType="phone-pad"
            label="Phone number"
            leftIcon="ipadMediumDefault"
            size="large"
            testProps={{
              elementId: "phoneNumber",
              elementName: "OnboardingForm",
              screenName: route.name,
            }}
            {...maskedInputProps}
          />
          {showFormErrors && formErrors?.fieldErrors.phoneNumber !== undefined ? (
            <Body style={styles.textInputErrorText}>{formErrors.fieldErrors.phoneNumber}</Body>
          ) : null}
        </View>
        <View style={styles.lineSeparator} />
        <View style={styles.checkboxContainer}>
          <Checkbox
            defaultChecked={rawIsCoppaChecked}
            disabled={!areFormsInitialized}
            label={"Check the box to confirm you are over the age of\u00a013"}
            onChange={value => {
              setRawIsCoppaChecked(value);
            }}
            testProps={{
              elementId: "coppa",
              elementName: "OnboardingForm",
              screenName: route.name,
            }}
          />
          {showFormErrors && formErrors?.fieldErrors.isCoppaChecked !== undefined ? (
            <Body style={styles.checkboxErrorText}>{validationErrorMessages.isCoppaChecked}</Body>
          ) : null}
        </View>
      </View>
      {/* TODO (PE-542): show loading state in the button */}
      <Button
        disabled={
          !areFormsInitialized ||
          isRequestInFlight ||
          isRequestSuccessful ||
          (showFormErrors && formErrors !== undefined)
        }
        onPress={onPressAccept}
        testProps={{ elementId: "submit", elementName: "OnboardingForm", screenName: route.name }}
        text={isRequestInFlight ? "Submitting…" : "Submit"}
      />
    </View>
  );
};
