import { MeritsList } from "./MeritsList";
import { SideBarContentHeader } from "./SideBarContentHeader";
import { byNameThenOrg, containsText, hasState } from "@src/utils";
import { useMemo, useState } from "react";
import { useMerits } from "@src/api/issuance";

export const MeritsScreen = () => {
  const { data: merits } = useMerits();

  const [searchText, setSearchText] = useState("");

  const displayedMerits = useMemo(
    () =>
      merits
        ?.filter(hasState(["accepted", "pending"]))
        .filter(containsText(searchText))
        .sort(byNameThenOrg),
    [merits, searchText]
  );

  const handleChangeText = (text: string) => {
    setSearchText(text);
  };

  return (
    <>
      <SideBarContentHeader onChangeText={handleChangeText} title="Merits" />
      <MeritsList merits={displayedMerits} />
    </>
  );
};
