import { APPS } from "./constants";
import { isAdminMerit } from "@src/api/issuance";
import MeritLogo from "@src/assets/images/merit-logotype.png";
import type { AppDetails } from "./types";
import type { Configuration } from "@src/configuration";
import type { Merit } from "@src/api/issuance";

type MeritFilter = (merit: Merit, config?: Configuration) => boolean;

export const isAcePgMerit: MeritFilter = merit =>
  merit.fieldMap["Issuing Org Name"] === APPS.ohioAce.orgName &&
  (merit.name?.includes(APPS.ohioAce.parentGuardian.meritName) ?? false);

export const isAceSpMerit: MeritFilter = merit =>
  merit.fieldMap["Issuing Org Name"] === APPS.ohioAce.orgName &&
  (merit.name?.includes(APPS.ohioAce.serviceProvider.meritName) ?? false);

export const isKeepPgMerit: MeritFilter = merit =>
  merit.fieldMap["Issuing Org Name"] === APPS.kansasKeep.orgName &&
  (merit.name?.includes(APPS.kansasKeep.parentGuardian.meritName) ?? false);

export const isKeepSpMerit: MeritFilter = merit =>
  merit.fieldMap["Issuing Org Name"] === APPS.kansasKeep.orgName &&
  (merit.name?.includes(APPS.kansasKeep.serviceProvider.meritName) ?? false);

export const hasAppAttached: MeritFilter = (merit, config) =>
  isAdminMerit(merit) ||
  isAcePgMerit(merit, config) ||
  isAceSpMerit(merit, config) ||
  isKeepPgMerit(merit, config) ||
  isKeepSpMerit(merit, config);

type GetAppDetails = (merit: Merit, config: Configuration) => AppDetails | undefined;

export const getAppDetails: GetAppDetails = (merit, config) => {
  if (isAdminMerit(merit)) {
    return {
      id: "orgPortal",
      logo: MeritLogo,
      name: "Org Portal",
      url: config.orgPortal.url,
    };
  }

  if (isAcePgMerit(merit, config)) {
    return APPS.ohioAce.parentGuardian;
  }

  if (isAceSpMerit(merit, config)) {
    return APPS.ohioAce.serviceProvider;
  }

  if (isKeepPgMerit(merit, config)) {
    return APPS.kansasKeep.parentGuardian;
  }

  if (isKeepSpMerit(merit, config)) {
    return APPS.kansasKeep.serviceProvider;
  }

  return undefined;
};
