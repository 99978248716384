import { ActivityIndicator, StyleSheet, View } from "react-native";
import { Body, useTheme } from "@merit/frontend-components";
import { ContainerFieldsList } from "../../ContainerFieldsList";
import { useMeritDetails } from "../MeritDetails/hooks";
import { useMerits } from "@src/api/issuance";
import type { Container } from "@merit/issuance-client";
import type { ViewStyle } from "react-native";

type Props = {
  readonly meritId: Container["id"];
};

export const MeritTabDetails = ({ meritId }: Props) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
  }>({
    container: {
      backgroundColor: theme.colors.background.white,
      paddingHorizontal: theme.spacing.l,
      paddingVertical: theme.spacing.m,
    },
  });
  const { isError, isLoading } = useMerits();
  const { matchingMerit } = useMeritDetails({ meritId });

  if (isError) {
    return (
      <View style={styles.container}>
        <Body>Error loading merit</Body>
      </View>
    );
  }

  if (isLoading || matchingMerit?.fields === undefined) {
    return (
      <View style={styles.container}>
        <ActivityIndicator />
      </View>
    );
  }

  // note: some fields may not be rendered at all, or rendered specially, see ContainerFieldsList
  return <ContainerFieldsList fields={matchingMerit.fields} />;
};
