import { Body, Button, Heading, useTheme } from "@merit/frontend-components";
import { BottomSlideUpModal } from "../BottomSlideUpModal";
import { StyleSheet, View } from "react-native";
import { useRoute } from "@src/hooks";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import type { ViewStyle } from "react-native";

export enum DestructiveActionType {
  rejectMerit = "rejectMerit",
  removeMerit = "removeMerit",
}

type DestructiveActionConfirmationPropsBase = {
  readonly isLoading?: boolean;
  readonly isVisible: boolean;
  readonly onPressCancel: () => Promise<void> | void;
  readonly onPressRemove: () => Promise<void> | void;
};

type DestructiveActionConfirmationPropsWithTextSet = DestructiveActionConfirmationPropsBase & {
  readonly type?: never;
  readonly textSet: TextSet;
};

type DestructiveActionConfirmationPropsWithType = DestructiveActionConfirmationPropsBase & {
  readonly type: DestructiveActionType;
  readonly textSet?: never;
};

export type DestructiveActionConfirmationProps =
  | DestructiveActionConfirmationPropsWithTextSet
  | DestructiveActionConfirmationPropsWithType;

export type TextSet = {
  readonly heading: string;
  readonly body: string;
  readonly button: string;
  readonly verbProgressiveTense: string;
  readonly verbPastTense: string;
};

export const texts: Record<DestructiveActionType, TextSet> = {
  [DestructiveActionType.rejectMerit]: {
    body: "Are you sure you want to reject this merit?",
    button: "Reject",
    heading: "Reject Merit",
    verbPastTense: "rejected",
    verbProgressiveTense: "rejecting",
  },
  [DestructiveActionType.removeMerit]: {
    body: "Are you sure you want to remove this merit? You cannot view this merit in your list anymore if you remove.",
    button: "Remove",
    heading: "Remove this merit",
    verbPastTense: "removed",
    verbProgressiveTense: "removing",
  },
};

export const DestructiveActionConfirmation = ({
  isLoading = false,
  isVisible,
  onPressCancel,
  onPressRemove,
  textSet,
  type,
}: DestructiveActionConfirmationProps) => {
  const { bottom: bottomSafeInset } = useSafeAreaInsets();
  const route = useRoute();
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly actionButtonsContainer: ViewStyle;
    readonly bottomSpace: ViewStyle;
    readonly cancelButton: ViewStyle;
    readonly contentContainer: ViewStyle;
  }>({
    actionButtonsContainer: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    bottomSpace: {
      marginBottom: theme.spacing.m,
    },
    cancelButton: {
      marginRight: theme.spacing.s,
    },
    contentContainer: {
      backgroundColor: theme.colors.background.white,
      padding: theme.spacing.l,
      paddingBottom: Math.max(theme.spacing.l, bottomSafeInset),
    },
  });

  return (
    <BottomSlideUpModal
      appearanceType="drawer"
      contentContainerStyle={styles.contentContainer}
      isVisible={isVisible}
      onClose={() => {
        onPressCancel();
      }}
    >
      <Heading bold level="3" style={styles.bottomSpace}>
        {textSet === undefined ? texts[type].heading : textSet.heading}
      </Heading>
      <Body size="l" style={styles.bottomSpace}>
        {textSet === undefined ? texts[type].body : textSet.body}
      </Body>
      <View style={styles.actionButtonsContainer}>
        <View style={styles.cancelButton}>
          <Button
            disabled={isLoading}
            onPress={() => {
              onPressCancel(); // need to wrap because Pressable expects () => void
            }}
            testProps={{
              elementId: "cancelButton",
              elementName: "DestructiveActionConfirmation",
              screenName: route.name,
            }}
            text="Cancel"
            type="secondary"
          />
        </View>
        <Button
          disabled={isLoading}
          onPress={onPressRemove}
          testProps={{
            elementId: "destructiveButton",
            elementName: "DestructiveActionConfirmation",
            screenName: route.name,
          }}
          text={textSet === undefined ? texts[type].button : textSet.button}
          type="destructive"
        />
      </View>
    </BottomSlideUpModal>
  );
};
