import { ActivityIndicator, StyleSheet, View } from "react-native";
import { Heading, useTheme } from "@merit/frontend-components";
import { useEffect } from "react";
import { useLogout } from "@src/hooks";
import type { TextStyle, ViewStyle } from "react-native";

const LoggingOut = () => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly heading: TextStyle;
  }>({
    container: {
      alignItems: "center",
      flex: 1,
      justifyContent: "center",
    },
    heading: {
      marginBottom: theme.spacing.xxl,
    },
  });

  const logout = useLogout();

  useEffect(() => {
    setTimeout(() => {
      logout();
    }, 1000);
  }, [logout]);

  return (
    <View style={styles.container}>
      <Heading level="2" style={styles.heading}>
        Logging you out…
      </Heading>
      <ActivityIndicator />
    </View>
  );
};

export { LoggingOut };
