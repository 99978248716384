import { Body, Button, Checkbox, Heading, Link, useTheme } from "@merit/frontend-components";
import { Constants, openExternalLink } from "@src/utils";
import { Header } from "@src/components";
import { Image, Platform, StyleSheet, View } from "react-native";
import { MERIT_LOGOTYPE } from "@src/utils/constants/sizes";
import { useConfirmTOS } from "@src/api/person-experience-backend";
import { useLayoutType, useNavigation, useRoute } from "@src/hooks";
import { useState } from "react";
import MeritLogo from "@src/assets/images/merit-logotype.png";

export const AcceptTermsOfService = () => {
  const route = useRoute<"TermsOfServiceAcceptance">();
  const navigation = useNavigation();
  const [tosAccepted, setTOSAccepted] = useState<boolean>(false);
  const [ppAccepted, setPPAccepted] = useState<boolean>(false);
  const { theme } = useTheme();
  const layoutType = useLayoutType();
  const confirmTOS = useConfirmTOS();

  const sessionToken = route.params.session_token;
  const state = route.params.state;
  const isWeb = Platform.OS === "web";
  const styles = StyleSheet.create({
    accept: { marginLeft: theme.spacing.xs },
    bg: {
      backgroundColor: theme.colors.background.default,
      height: "100%",
    },
    card: {
      backgroundColor: theme.colors.background.white,
      borderRadius: theme.borderRadii.s,
      marginBottom: 40,
      marginLeft: "auto",
      marginRight: "auto",
      padding: theme.spacing.xl,
      width: 420,
    },
    expand: {
      flexGrow: 1,
    },
    footer: {
      alignItems: "center",
      alignSelf: "flex-end",
      backgroundColor: theme.colors.background.white,
      borderTopColor: theme.colors.border.shadow.subdued,
      borderTopWidth: theme.spacing.xxs,
      justifyContent: "center",
      padding: theme.spacing.l,
      width: "100%",
    },
    footerButton: {
      marginRight: theme.spacing.l,
    },
    footerContainer: {
      flexDirection: "row",
      justifyContent: "flex-end",
      width: "100%",
    },
    header: {
      backgroundColor: theme.colors.background.white,
      borderBottomColor: theme.colors.border.shadow.subdued,
      borderBottomWidth: theme.spacing.xxs,
      marginBottom: 40,
    },
    information: {
      marginBottom: theme.spacing.l,
      marginTop: theme.spacing.l,
    },
    line: {
      lineHeight: 40,
    },
    mobileCard: {
      backgroundColor: theme.colors.background.white,
      height: "100%",
    },
    mobileHeader: {
      display: "none",
    },
    mobileLinksContainer: {
      padding: 30,
    },
    termsOfServiceContainer: {
      backgroundColor: theme.colors.brand.oceanBlue,
      borderRadius: theme.borderRadii.s,
      height: 140,
      marginBottom: theme.spacing.xl,
    },
    termsOfServiceContainerMobile: {
      backgroundColor: theme.colors.brand.oceanBlue,
      height: 120,
    },
    termsOfServiceImage: {
      ...MERIT_LOGOTYPE.l,
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: 56,
    },
    termsOfServiceImageMobile: {
      ...MERIT_LOGOTYPE.l,
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: 44,
    },
  });

  if (sessionToken === "") {
    navigation.navigate("Login");
  }

  const request = async (tosAcceptedReq: boolean, ppAcceptedReq: boolean) => {
    const result = await confirmTOS.mutateAsync({
      body: {
        ppAccepted: ppAcceptedReq,
        state,
        token: sessionToken,
        tosAccepted: tosAcceptedReq,
      },
    });
    if (isWeb) {
      /* eslint-disable functional/immutable-data */
      window.location.href = result.redirect;
    } else {
      await openExternalLink(result.redirect);
    }
  };

  const title = "Terms of Service";

  return (
    <View style={styles.bg}>
      <Header
        containerStyle={layoutType === "desktop" ? styles.header : styles.mobileHeader}
        title={title}
      />
      <View style={styles.expand}>
        <View style={layoutType === "desktop" ? styles.card : styles.mobileCard}>
          <View
            style={
              layoutType === "desktop"
                ? styles.termsOfServiceContainer
                : styles.termsOfServiceContainerMobile
            }
          >
            <Image
              source={MeritLogo}
              style={
                layoutType === "desktop"
                  ? styles.termsOfServiceImage
                  : styles.termsOfServiceImageMobile
              }
            />
          </View>
          <View style={layoutType === "desktop" ? null : styles.mobileLinksContainer}>
            <Heading level="3">We've updated our Terms</Heading>
            <Body style={styles.information}>
              To continue using Merit, please review & accept the updated terms and conditions.
            </Body>
            <Body style={styles.line}>
              <Checkbox
                accessibilityLabel="Accept Terms of Service"
                defaultChecked={tosAccepted}
                disabled={confirmTOS.isLoading || confirmTOS.isSuccess}
                label={
                  <Body style={styles.accept}>
                    I accept the{" "}
                    <Link
                      color={theme.colors.text.link}
                      onPress={() => {
                        openExternalLink(Constants.URLs.TOS);
                      }}
                    >
                      Terms of Service
                    </Link>
                  </Body>
                }
                onChange={checked => {
                  setTOSAccepted(checked);
                }}
                size="small"
                testProps={{ elementName: "tosCheckbox", screenName: route.name }}
              />
            </Body>
            <Body style={styles.line}>
              <Checkbox
                accessibilityLabel="Accept Privacy Notice"
                defaultChecked={ppAccepted}
                disabled={confirmTOS.isLoading || confirmTOS.isSuccess}
                label={
                  <Body style={styles.accept}>
                    I accept the{" "}
                    <Link
                      color={theme.colors.text.link}
                      onPress={() => {
                        openExternalLink(Constants.URLs.PRIVACY);
                      }}
                    >
                      Privacy Notice
                    </Link>
                  </Body>
                }
                onChange={checked => {
                  setPPAccepted(checked);
                }}
                size="small"
                testProps={{ elementName: "privacyCheckbox", screenName: route.name }}
              />
            </Body>
          </View>
        </View>
      </View>
      <View style={styles.footer}>
        <View style={styles.footerContainer}>
          <Button
            accessibilityLabel="Log out"
            disabled={confirmTOS.isLoading || confirmTOS.isSuccess}
            onPress={() => request(false, false)}
            style={styles.footerButton}
            text="Log out"
            type="secondary"
          />
          <Button
            accessibilityLabel="Submit"
            disabled={!tosAccepted || !ppAccepted || confirmTOS.isLoading || confirmTOS.isSuccess}
            onPress={() => request(tosAccepted, ppAccepted)}
            style={styles.footerButton}
            text="Submit"
          />
        </View>
      </View>
    </View>
  );
};
