import { Body, Heading, Link, useTheme } from "@merit/frontend-components";
import { Image, Pressable, StyleSheet, View } from "react-native";
import { MERIT_LOGOTYPE } from "@src/utils/constants/sizes";
import { openExternalLink } from "@src/utils";
import { useNavigation } from "@src/hooks";
import MeritLogoType from "@src/assets/images/merit-logotype.png";
import SomethingWentWrongArtwork from "@src/assets/images/something-went-wrong-artwork.png";
import type { ImageStyle, TextStyle, ViewStyle } from "react-native";

export const SomethingWentWrong = () => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly artwork: ImageStyle;
    readonly body: TextStyle;
    readonly container: ViewStyle;
    readonly contentContainer: ViewStyle;
    readonly heading: TextStyle;
    readonly meritLogotype: ImageStyle;
  }>({
    artwork: {
      height: 160,
      marginBottom: 62,
      resizeMode: "contain",
      width: 160,
    },
    body: {
      textAlign: "center",
    },
    container: {
      alignItems: "center",
      backgroundColor: theme.colors.background.white,
      flex: 1,
    },
    contentContainer: {
      alignItems: "center",
      paddingHorizontal: theme.spacing.xxl,
      paddingTop: 48,
    },
    heading: {
      marginBottom: theme.spacing.l,
    },
    meritLogotype: {
      ...MERIT_LOGOTYPE.l,
      marginBottom: 120,
    },
  });

  const { goHome } = useNavigation();

  return (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        <Pressable
          onPress={() => {
            goHome();
          }}
        >
          <Image source={MeritLogoType} style={styles.meritLogotype} />
        </Pressable>
        <Image source={SomethingWentWrongArtwork} style={styles.artwork} />
        <Heading level="1" style={styles.heading}>
          Something went wrong
        </Heading>
        <Body size="l" style={styles.body}>
          Please go back and try again or{" "}
          <Link onPress={() => openExternalLink("mailto:help@gomerits.com")} size="l">
            contact Merit Support
          </Link>{" "}
          if this issue persists
        </Body>
      </View>
    </View>
  );
};
