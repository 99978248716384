import { Log } from "@src/utils";
import { useAlerts } from "@src/hooks";
import { useMutation } from "@tanstack/react-query";
import { useTOSApi } from "./useTOSApi";
import jwtDecode from "jwt-decode";
import type { TOSApiConfirmTOSOperationRequest } from "@merit/person-experience-backend-client";

export type Response = {
  readonly redirect: string;
};

type Jwt = {
  readonly iat: string;
  readonly iss: string;
  readonly sub: string;
  readonly exp: string;
  readonly ip: string;
  readonly email: string;
};

export const useConfirmTOS = () => {
  const { api: tosAPI } = useTOSApi();
  const { sendAlert } = useAlerts();

  return useMutation({
    mutationFn: async (req: TOSApiConfirmTOSOperationRequest) => {
      const result = await tosAPI.confirmTOS(req);
      const data = jwtDecode<Jwt>(result.token);
      // https://auth0.com/docs/customize/actions/flows-and-triggers/login-flow/redirect-with-actions#pass-data-to-the-external-site
      const domain = data.iss;
      const scheme = "https";
      const redirect = `${scheme}://${domain}/continue?state=${req.body.state}&session_token=${result.token}`;

      return { redirect };
    },
    onError: err => {
      Log.error(`Error confirming TOS`, { error: err });
      sendAlert({
        id: "confirmTOS-error",
        text1: "Error accepting TOS",
        text2: "Something went wrong",
        type: "error",
      });
    },
  });
};
