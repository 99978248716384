import { Log } from "@src/utils";
import { useAccountFolioFromAdminMerit } from "./useAccountFolioFromAdminMerit";
import { useAlerts, useMeritAuth0 } from "@src/hooks";
import { useMemberApi } from "./useMemberApi";
import { useMutation } from "@tanstack/react-query";
import type { AdminMerit } from "../issuance/types";

export const useAcceptFolio = (adminMeritId: AdminMerit["id"]) => {
  const { api: memberApi } = useMemberApi();
  const { sendAlert } = useAlerts();
  const { refetch } = useAccountFolioFromAdminMerit(adminMeritId);
  const { idToken } = useMeritAuth0();

  return useMutation({
    mutationFn: () =>
      memberApi.acceptAccountFolioFromAdminMerit({ adminMeritId, xIDToken: idToken }),
    onError: err => {
      Log.error(`Error accepting folio from admin merit ${adminMeritId} ${String(err)}`);
      sendAlert({
        id: "useAcceptFolio-Error",
        text1: "Error accepting your folio",
        text2: String(err),
        type: "error",
      });
    },
    onMutate: () => {
      sendAlert({
        id: "useAcceptFolio-Accepting",
        text1: "Accepting…",
        type: "info",
      });
    },
    onSettled: () => {
      refetch();
    },
    onSuccess: () => {
      sendAlert({
        id: "useAcceptFolio-Success",
        text1: "Your folio has been accepted",
        type: "success",
      });
    },
  });
};
