import { Badge, Body, Heading, useTheme } from "@merit/frontend-components";
import { StyleSheet, View } from "react-native";
import { useGetTestProps } from "@src/hooks";
import { useRoute } from "@react-navigation/native";
import type { EmailListProps } from "./types";
import type { TextStyle, ViewStyle } from "react-native";

const EmailList = ({ emails }: EmailListProps) => {
  const getTestProps = useGetTestProps();
  const { theme } = useTheme();
  const route = useRoute();

  const styles = StyleSheet.create<{
    readonly emailContainer: ViewStyle;
    readonly emailHeadingContainer: ViewStyle;
    readonly emailInstructionsContainer: ViewStyle;
    readonly primaryEmailHeading: TextStyle;
    readonly emailListItem: ViewStyle;
    readonly emailListItemText: TextStyle;
  }>({
    emailContainer: {
      display: "flex",
      marginTop: theme.spacing.xxl,
    },
    emailHeadingContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: theme.spacing.xl,
    },
    emailInstructionsContainer: {
      display: "flex",
    },
    emailListItem: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingVertical: theme.spacing.xxl,
    },
    emailListItemText: {
      marginRight: theme.spacing.xxl,
    },
    primaryEmailHeading: {
      ...theme.fontSizes.l,
    },
  });

  return (
    <View
      style={styles.emailContainer}
      {...getTestProps({
        elementName: "EmailList",
      })}
    >
      <View style={styles.emailHeadingContainer}>
        <View style={styles.emailInstructionsContainer}>
          <Heading
            level="1"
            style={styles.primaryEmailHeading}
            testProps={{
              elementName: "ChoosePrimaryEmailText",
              screenName: route.name,
            }}
          >
            Account email
          </Heading>
          <Body
            testProps={{
              elementName: "NotificationText",
              screenName: route.name,
            }}
          >
            The primary email will be used for all email notifications
          </Body>
        </View>
      </View>
      {emails.map(email => (
        <View key={email.email} style={styles.emailListItem}>
          <Body style={styles.emailListItemText}>{email.email}</Body>
          {email.isPrimary ? <Badge text="Primary" /> : null}
        </View>
      ))}
    </View>
  );
};

export { EmailList };
