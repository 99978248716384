import { MainHeader } from "@src/components";
import { SearchableMeritsList } from "./SearchableMeritsList";

const MeritsScreen = () => (
  <>
    <MainHeader title="Merits" />
    <SearchableMeritsList />
  </>
);

export { MeritsScreen };
