import { MeritTabActivity } from "./MeritTabActivity";
import { MeritTabDescription } from "./MeritTabDescription";
import { MeritTabDetails } from "./MeritTabDetails";
import { MeritTabFilesAndMedia } from "./MeritTabFilesAndMedia";
import { Tabs } from "../../Tabs";
import { getActivityTemplateFieldValueFromContainerFields } from "@src/utils";
import { useFeatureFlags } from "@src/hooks";
import { useMemo } from "react";
import { useMerit } from "@src/api/issuance";
import type { Container } from "@merit/issuance-client";

type MeritTabsProps = {
  readonly meritId: Container["id"];
};

export const MeritTabs = ({ meritId }: MeritTabsProps) => {
  const { data: featureFlags } = useFeatureFlags();
  const merit = useMerit(meritId);
  const activityTemplateFieldValue = getActivityTemplateFieldValueFromContainerFields(
    merit?.fields ?? []
  );

  const showActivityTab =
    featureFlags?.showMeritActivityTab === true &&
    activityTemplateFieldValue !== undefined &&
    activityTemplateFieldValue.checkInDetails.length > 0;
  const showDescriptionTab = merit?.description !== undefined;

  const tabs = useMemo(() => {
    // eslint-disable-next-line no-underscore-dangle
    const _tabs = [
      {
        content: <MeritTabDetails meritId={meritId} />,
        id: "detailsTab",
        title: "DETAILS",
      },
      {
        content: <MeritTabFilesAndMedia meritId={meritId} />,
        id: "filesAndMediaTab",
        title: "FILES & MEDIA",
      },
    ];

    if (showDescriptionTab) {
      // eslint-disable-next-line functional/immutable-data
      _tabs.push({
        content: <MeritTabDescription meritId={meritId} />,
        id: "descriptionTab",
        title: "DESCRIPTION",
      });
    }

    if (showActivityTab) {
      // eslint-disable-next-line functional/immutable-data
      _tabs.push({
        content: <MeritTabActivity activityTemplateFieldValue={activityTemplateFieldValue} />,
        id: "activityTab",
        title: "ACTIVITY",
      });
    }

    return _tabs;
  }, [activityTemplateFieldValue, meritId, showActivityTab, showDescriptionTab]);

  return <Tabs tabs={tabs} />;
};
