import { Log, findAndMerge } from "@src/utils";
import { PatchContainerStateEnum } from "@merit/issuance-client";
import { capitalize } from "@src/utils/string";
import { useAlerts } from "@src/hooks";
import { useContainersApi } from "./useContainersApi";
import { useMerits, useMeritsQueryKey } from "./useMerits";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { Merit } from "./types";

const verbTenses = { past: "accepted", progressive: "accepting" };

// TODO: extract into a common MutationOptions type for other hooks
type Options = {
  readonly showErrorToast: boolean;
  readonly showMutatingToast: boolean;
  readonly showSuccessToast: boolean;
};

const defaultOptions: Options = {
  showErrorToast: true,
  showMutatingToast: true,
  showSuccessToast: true,
};

export const useAcceptMerit = (options: Partial<Options> = defaultOptions) => {
  const { api: containersApi } = useContainersApi();
  const { sendAlert } = useAlerts();
  const { refetch } = useMerits();
  const queryKey = useMeritsQueryKey();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (meritId: Merit["id"]) =>
      containersApi.patchContainer({
        containerID: meritId,
        properties: {
          state: PatchContainerStateEnum.Accepted,
        },
      }),
    onError: (err, meritId) => {
      // We could potentially roll back here. Currently we just refetch onSettled.
      Log.error(`Error ${verbTenses.progressive} merit ${meritId} ${String(err)}`);
      if (options.showErrorToast === true) {
        sendAlert({
          id: "useAcceptMerit-Error",
          text1: `Error ${verbTenses.progressive} your merit`,
          text2: String(err),
          type: "error",
        });
      }
    },
    onMutate: async meritId => {
      if (options.showMutatingToast === true) {
        sendAlert({
          id: "useAcceptMerit-Accepting",
          text1: `${capitalize(verbTenses.progressive)}…`,
          type: "info",
        });
      }

      // Cancel any outgoing refetches, so they don't overwrite our optimistic update
      await queryClient.cancelQueries({ queryKey });

      // Snapshot the previous value
      const merits: readonly Merit[] | undefined = queryClient.getQueryData(queryKey);

      if (merits === undefined) {
        return undefined;
      }

      const newMerits = findAndMerge(merits, m => m.id === meritId, {
        state: { name: PatchContainerStateEnum.Accepted, occurredAt: new Date().toISOString() },
      });

      if (newMerits === undefined) {
        return undefined;
      }

      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, newMerits);

      // Return a context object with the snapshotted value
      return { merits };
    },
    onSettled: () => {
      refetch();
    },
    onSuccess: () => {
      if (options.showSuccessToast === true) {
        sendAlert({
          id: `useAcceptMerit-Success`,
          text1: `Your merit has been ${verbTenses.past}`,
          type: "success",
        });
      }
    },
  });
};
