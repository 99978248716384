import { Auth0Client } from "@auth0/auth0-spa-js";
import { Log } from "@src/utils";
import { allAuthScopesString, audience, stellarAuthScopesString } from "./common";
import { useAlerts } from "../useAlerts";
import { useAuth0 } from "@auth0/auth0-react";
import { useConfig } from "../useConfig";
import { useEffect, useState } from "react";
import { useOnboardingStore } from "@src/stores";
import { useQueryClient } from "@tanstack/react-query";
import jwtDecode from "jwt-decode";
import type { GenericError } from "@auth0/auth0-spa-js";
import type { MeritUserInfo } from "@src/types/user";
import type { UseMeritAuth0 } from "@src/types/auth";
import type { UseSecondaryAccountHook } from "./types";

// These numbers are the defaults auth0 uses
const POPUP_WIDTH = 400;
const POPUP_HEIGHT = 600;

const getPopup = () => {
  const left = window.screenX + (window.innerWidth - POPUP_WIDTH) / 2;
  const top = window.screenY + (window.innerHeight - POPUP_HEIGHT) / 2;

  return window.open(
    "",
    "auth0:authorize:popup",
    `left=${left},top=${top},width=${POPUP_WIDTH},height=${POPUP_HEIGHT},resizable,scrollbars=yes,status=1`
  );
};

const useLogin = () => {
  const { loginWithPopup } = useAuth0();
  const { sendAlert } = useAlerts();

  const doPkceFlow = async () => {
    const popup = getPopup();
    try {
      const scope = allAuthScopesString;
      await loginWithPopup(
        {
          authorizationParams: {
            audience,
            prompt: "login",
            scope,
          },
        },
        { popup, timeoutInSeconds: 60 * 30 }
      );
      // Refresh the page (only on web) due to bug in useAuth0
      // isAuthenticated does not update when loginWithPopup returns
      // TODO: as of 2024-01-26 this doesn't seem necessary, tested on FF121 -Jude
      window.location.reload();
    } catch (err) {
      Log.error(`Failed to login: ${String(err)}`);
      sendAlert({
        id: "doPkceFlow-web-error",
        text1: "There was a problem logging in",
        type: "error",
      });
    } finally {
      popup?.close();
    }
  };

  return doPkceFlow;
};

const useLogout = () => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { logout: logoutAuth0 } = useAuth0();
  const queryClient = useQueryClient();

  const logout = () => {
    logoutAuth0({ logoutParams: { returnTo: window.location.origin } });
    queryClient.clear();
  };

  return logout;
};

const useMeritAuth0 = (): UseMeritAuth0 => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { getAccessTokenSilently, getIdTokenClaims, isAuthenticated, isLoading, user } =
    useAuth0<MeritUserInfo>();
  const config = useConfig();

  const [accessToken, setAccessToken] = useState<string>();
  const [idToken, setIdToken] = useState<string>();

  useEffect(() => {
    // eslint-disable-next-line functional/no-let
    let hasCleanedUp = false;
    const setTokens = async () => {
      // eslint-disable-next-line functional/no-let
      let theAccessToken = "";
      try {
        theAccessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience,
            scope: stellarAuthScopesString,
          },
        });
      } catch (err) {
        // catch and suppress "login_required" errors, it's NOT indicative of a real problem, it's a catchable error in case we want to handle it
        // see also https://community.auth0.com/t/getaccesstokensilently-throws-error-login-required/52333/4
        // and https://github.com/auth0/auth0-spa-js/blob/main/EXAMPLES.md#refresh-token-fallback
        if ((err as GenericError | undefined)?.error !== "login_required") {
          throw err;
        }
      }
      // eslint-disable-next-line no-underscore-dangle
      const theIdToken = (await getIdTokenClaims())?.__raw;
      if (!hasCleanedUp) {
        setAccessToken(theAccessToken);
        setIdToken(theIdToken);
      }
    };

    // Only try to get the accessToken if we are authenticated to avoid console errors
    if (isAuthenticated) {
      setTokens();
    }

    return () => {
      hasCleanedUp = true;
    };
  }, [config.api.stellarApiBasePath, getAccessTokenSilently, getIdTokenClaims, isAuthenticated]);

  useEffect(() => {
    if (user?.email !== undefined) {
      const storeName = `${user.email}-onboarding`;
      const persistStoreName = useOnboardingStore.persist.getOptions().name;
      if (persistStoreName !== storeName) {
        useOnboardingStore.persist.clearStorage();
        useOnboardingStore.persist.setOptions({
          name: storeName,
        });
        useOnboardingStore.persist.rehydrate();
      }
    }
  }, [user]);

  return {
    accessToken: accessToken ?? "",
    idToken: idToken ?? "",
    isAuthenticated,
    isLoading,
    // Looks weird, but convert undefined to null for matching API
    user: user === undefined ? null : user,
  };
};

const useSecondaryAccount: UseSecondaryAccountHook = () => {
  const config = useConfig();

  const login = async () => {
    const getNewAuthClient = () => {
      try {
        return new Auth0Client({
          clientId: config.auth.clientId,
          domain: config.auth.auth0Domain,
        });
      } catch (err) {
        Log.error(`Failed to create Auth0 client in useSecondaryAccount: ${String(err)}`);
      }

      return undefined;
    };

    const auth0 = getNewAuthClient();

    if (auth0 === undefined) {
      Log.error(`Auth0 client in useSecondaryAccount is undefined`);

      return undefined;
    }

    const getIdTokenClaims = async () => {
      try {
        const popup = getPopup();

        await auth0.loginWithPopup(
          {
            authorizationParams: {
              audience,
              prompt: "login",
              scope: allAuthScopesString,
            },
          },
          { popup, timeoutInSeconds: 60 * 30 }
        );

        const token = await auth0.getIdTokenClaims();

        return token;
      } catch (err) {
        Log.error(`Failed to getIdTokenClaims in useSecondaryAccount: ${String(err)}`);
      }

      return undefined;
    };

    const idTokenClaims = await getIdTokenClaims();

    if (idTokenClaims === undefined) {
      Log.error("idTokenClaims from useSecondaryAccount is undefined");

      return undefined;
    }

    const getDecodedJwt = () => {
      try {
        // eslint-disable-next-line no-underscore-dangle
        return jwtDecode<MeritUserInfo>(idTokenClaims.__raw);
      } catch (err) {
        Log.error(`Failed to decode JWT in useSecondaryAccount: ${String(err)}`);
      }

      return undefined;
    };

    const decodedJwt = getDecodedJwt();

    if (decodedJwt === undefined) {
      return undefined;
    }

    const accessToken = await auth0.getTokenSilently({
      authorizationParams: {
        audience,
        scope: stellarAuthScopesString,
      },
    });

    return {
      accessToken,
      email: decodedJwt.email ?? "",
      entityId: decodedJwt.entityID,
    };
  };

  return { login };
};

export { useLogin, useLogout, useMeritAuth0, useSecondaryAccount };
